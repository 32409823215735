import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function MenuItem({ itemTitle, menuItems, currentPage }) {
  const [isOpen, setIsOpen] = useState(false);
  const [updatedMenuItems, setUpdatedMenuItems] = useState([]);
  const [isSinglePage, setIsSinglePage] = useState(false);
  const navigate = useNavigate("");

  console.log(`${itemTitle} : ${isOpen}`);

  useEffect(() => {
    // #1: Determine if there are subpages
    setIsSinglePage(true ? menuItems.length === 0 : false);

    // #2: Parse page titles into readable for page
    const parsedMenuitems = menuItems.map((item) => {
      return item.replace(" ", "").toLowerCase();
    });
    setUpdatedMenuItems(parsedMenuitems);

    // // #3: Check if current page (or subpages) are open
    if (itemTitle.toLowerCase().replace(" ", "") === currentPage) {
      console.log(itemTitle);
      console.log(currentPage);
      setIsOpen(true);
    }
  }, []);

  useEffect(() => {
    // If current page is title page, have menu default open
    if (
      currentPage.toLowerCase().replace(" ", "") ===
      itemTitle.toLowerCase().replace(" ", "")
    ) {
      console.log("this is this sections starting page");
      setIsOpen(true);
    }
  }, [currentPage]);

  // DESCRIPTION: Given a file path, navigate to it.
  function navigateTo(page) {
    navigate(page);
  }

  // DESCRIPTION: When menu or sub-menu item is clicked, decide what to do
  function changeOpen(isSubPage, page) {
    page = page.toLowerCase();
    console.log(`Selected page: ${page}`);

    // NOTE: TRYING TO DO 3 THINGS HERE
    // 1. Navigate to the new page (if it is a new page)
    // 2. Open/close menu if the CURRENT menu item is chosen
    // 3. Keep the menu open if a sub-menu item is chosen
    // 4. Do not do anything if the CURRENT sub-menu item is chosen

    // Sub-menu page
    if (isSubPage) {
      let isInCurrentMenu = false;
      for (let subItem in updatedMenuItems) {
        console.log(updatedMenuItems[subItem]);
        if (updatedMenuItems[subItem] === page) {
          isInCurrentMenu = true;
        }
      }

      // PROBLEM: Need to know where to navigate to
      if (isInCurrentMenu) {
        console.log(`Where to navigate to: ${page}`);
        setIsOpen(true);
        let path = getPathFromName(page);
        navigateTo(path);
      } else {
        setIsOpen(false);
        let path = getPathFromName(page);
        navigateTo(path);
        // navigateTo(`/${currentPage}/${page}`);
      }
    }
    // Main menu page
    else {
      if (page === currentPage) {
        setIsOpen(!isOpen);
      } else {
        navigateTo(`/${page.replace(/\s+/g, "").toLowerCase()}`);
      }
    }
  }

  return (
    <div className="overflow-hidden">
      {/* Menu title */}
      <div
        onClick={() => changeOpen(false, itemTitle)}
        className={`flex flex-row justify-between items-center px-2 rounded-md hover:cursor-pointer duration-200 ease-in-out
          ${
            currentPage === itemTitle.toLowerCase().replace(" ", "")
              ? "text-blue-700 bg-blue-50 font-normal"
              : "font-light text-gray-600 hover:bg-gray-100 hover:text-black"
          }
        `}
      >
        <h1 className={`text-sm`}>{itemTitle}</h1>
        <div>
          {isSinglePage ? (
            <div className="">
              <span
                className={`
                  material-symbols-outlined text-md mt-1 opacity-0
                  `}
              >
                chevron_right
              </span>
            </div>
          ) : (
            <div>
              <span
                className={`
                  material-symbols-outlined text-md mt-1 duration-700 ease-in-out
                  ${isOpen ? "rotate-90" : "rotate-0"}
                  `}
              >
                chevron_right
              </span>
            </div>
          )}
        </div>
      </div>

      {/* Submenu items */}
      {isSinglePage ? (
        <div></div>
      ) : (
        <div
          className={`
            flex flex-col justify-start items-start border-l border-gray-400 border-solid ml-4 pl-2 duration-700 ease-in-out text-gray-600 hover:text-black gap-[2px]
            ${isOpen ? "max-h-96 opacity-100" : "max-h-0 opacity-0"}
            `}
        >
          <div className="">
            {menuItems.map((item) => (
              <h1
                onClick={() =>
                  changeOpen(true, `${item.replace(/\s+/g, "").toLowerCase()}`)
                }
                className={`
                    text-start mb-[0.5] px-2 py-1 hover:bg-gray-100 rounded-md hover:cursor-pointer text-sm w-full duration-200 ease-in-out font-light hover:text-black
                    ${
                      currentPage === item.replace(/\s+/g, "").toLowerCase()
                        ? "text-blue-700 bg-blue-50 font-normal"
                        : ""
                    }
                  `}
              >
                {item}
              </h1>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export function getPathFromName(name) {
  let path = "";

  if (
    name === "artificialintelligence" ||
    name === "languagemodels" ||
    name === "promptengineering"
  ) {
    path = `/introduction/${name}`;
  }
  if (
    name === "precision" ||
    name === "labeling" ||
    name === "examples" ||
    name === "decomposition" ||
    name === "contextualization"
  ) {
    path = `/strategies/${name}`;
  }

  if (
    name === "frqs" ||
    name === "mcqs" ||
    name === "gradingmcqs" ||
    name === "gradingfrqs" ||
    name === "instructionfeedback" ||
    name === "roleplaying"
  ) {
    path = `/usecases/${name}`;
  }
  console.log(`${name} || ${path}`);
  return path;
}
