import React, { useState, useEffect } from "react";
import subsectionsData from "./subsections.json";
import { useNavigate } from "react-router-dom";

export default function PageOutline({ selectedPage }) {
  const navigate = useNavigate("");

  function navigateTo(page) {
    navigate(page);
  }

  const [subsections, setSubsections] = useState([]);

  useEffect(() => {
    setSubsections(subsectionsData[selectedPage]);
    //console.log(subsectionsData[selectedPage]);
  }, [selectedPage]);

  return (
    <div className="mt-20 top-0 right-0 bg-white fixed z-40 w-64 max-[1400px]:w-0 max-[1400px]:translate-x-64 duration-500 ease-in-out">
      <div className="flex flex-col px-4 py-4 gap-1 h-screen ">
        {subsections.length === 0 ? (
          <div></div>
        ) : (
          <div>
            <h1 className="pl-2 mt-4 font-semibold text-sm">On This Page</h1>
            <div className="mt-2 flex flex-col gap-2 pl-4 justify-center items-start border-b pb-8 border-gray-100 border-solid">
              {subsections.map((item, itemIndex) => (
                <a href={item[1]}>
                  <h1
                    className="font-normal text-sm text-gray-700 hover:text-blue-700 hover:cursor-pointer"
                    key={itemIndex}
                  >
                    {item[0]}
                  </h1>
                </a>
              ))}
              {/* <h1 className="font-normal text-sm text-blue-700 hover:cursor-pointer">
            <a href="#multiplechoicequestions">Introduction to Precision</a>
          </h1>
          <h1 className="font-light text-sm text-gray-700 hover:cursor-pointer">
            Experimenting
          </h1> */}
            </div>
            <h1 className="text-xs font-light text-gray-700 text-start mt-2">
              Questions or comments? Contact us
              <a
                href="https://www.facebook.com/groups/k12promptengineeringguide"
                target="_blank"
                className="hover:cursor-pointer hover:underline text-blue-700"
                rel="noreferrer"
              >
                {" here"}.
              </a>
            </h1>
          </div>
        )}
      </div>
    </div>
  );
}
