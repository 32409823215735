import React, { useState } from "react";
import { Breadcrumb } from "../../Components/ContentPage/ContentRendering/Breadcrumb";
import FooterNav from "../../Components/ContentPage/Menus/FooterNav";
import CompletionExample from "../../Components/ContentPage/ContentRendering/CompletionExample";

export default function Precision({}) {
  return (
    <div className="">
      <Breadcrumb page={"Strategies"} subPage={"Precision"} />
      <h1 className="font-semibold text-3xl mt-2 inline-block">Precision</h1>
      <p className="mt-3 font-light text-md text-gray-800">
        The first and most intuitive strategy to apply to your prompting is the
        concept of ‘precision’. By nature, LLMs are detail-oriented, overly
        pedantic systems that require precise information and instructions to
        effectively output text. Most failures of LLMs can be traced back to
        prompts that do not go far enough in describing what information the
        model should use and what it should do with that information. In
        computer science, this principle is called{" "}
        <a
          href="https://en.wikipedia.org/wiki/Garbage_in,_garbage_out"
          target="_blank"
          className="hover:cursor-pointer hover:underline text-blue-700"
          rel="noreferrer"
        >
          garbage in, garbage out.
        </a>
      </p>
      <div id="prompt1"></div>
      <p className="mt-6 font-light text-md text-gray-800">
        This is a common trap people fall into, particularly when getting
        started with LLMs. The point to internalize: vague prompts produce vague
        outputs. Therefore, developing the skill of writing descriptive and
        contextually organized (precise) prompts will go a long way in improving
        the value you receive from LLMs. Let’s take a look at the example of a
        9th grade biology teacher asking an LLM to generate a multiple-choice
        question (MCQ) for an upcoming quiz.
      </p>
      <CompletionExample
        title={`1: Writing a multiple-choice question`}
        prompt={`Write a MCQ for my quiz.`}
        completion={`Of course! Here's a multiple-choice question (MCQ) for your quiz:
Question:
What is the capital of France?
A) London
B) Berlin
C) Paris
D) Madrid
Correct Answer: C) Paris
`}
        comment={`This isn't particularly relevant to a 9th grade biology course.`}
        show={false}
        exampleUrl={
          "https://chat.openai.com/share/c260d343-a4c3-40b8-aa5a-ea0bdef20b26"
        }
      />
      <div id="prompt2"></div>
      <p className="mt-6 font-light text-md text-gray-800">
        This prompt is intentionally terrible for illustrative purposes. As you
        see from the output, the prompt is fundamentally imprecise because it
        assumes the model already knows you, your course, your students, and the
        quiz you wish to prepare, which it does not. Never assume the LLM
        inherently knows what you are referring to: be precise. We can draft a
        proper prompt by including some basic details.
      </p>

      <CompletionExample
        title={`2. A better MCQ`}
        prompt={`Write a multiple-choice question for my 9th grade biology unit on mitochondria.`}
        completion={`Certainly! Here's a multiple-choice question for your 9th-grade biology unit on mitochondria:
Which of the following statements about mitochondria is true?
A) Mitochondria are only found in animal cells.
B) Mitochondria are responsible for photosynthesis.
C) Mitochondria are the powerhouse of the cell, producing energy in the form of ATP.
D) Mitochondria store genetic information in the form of DNA.
Please select the correct answer from the options provided (A, B, C, or D).
`}
        comment={`Not bad!`}
        show={false}
        exampleUrl={
          "https://chat.openai.com/share/d6495429-e0ed-4be4-aaf7-7735c72b006e"
        }
      />

      <p className="mt-6 font-light text-md text-gray-800">
        This is a good starting point for our MCQ. You defined a task for the
        LLM to complete (generate a multiple choice question) and provided
        important contextual information that the model could not imply on its
        own (9th grade biology, mitochondria). In a task such as writing a MCQ,
        this is the minimum amount of detail you need to produce a good output.
      </p>
      <div id="prompt3"></div>
      <p className="mt-6 font-light text-md text-gray-800">
        But there is a lot more we can specify. The LLM assumed we wanted four
        answer options (do we?) It also assumed the subject matter on
        mitochondria we wished to evaluate with the question. Let’s improve this
        output by describing both of these explicitly in the prompt.
      </p>

      <CompletionExample
        title={`3. A more specific prompt`}
        prompt={`Write a multiple-choice question with 5 possible answers for my 9th grade biology class. The unit is on mitochondria with an emphasis on labeling the different components of the mitochondria when given a diagram.`}
        completion={`Certainly! Here's a multiple-choice question related to mitochondria for your 9th-grade biology class:
Which part of the mitochondria is responsible for producing most of the cell's ATP energy?
A) Outer Membrane
B) Inner Membrane
C) Matrix
D) Cristae
E) Intermembrane Space
Please let me know if you need any additional questions or information related to mitochondria or any other topic.
`}
        comment={`Notice the LLM leaves comments around the MCQ and answer options. If you want the LLM to only generate an MCQ and nothing else, specify at the end of the prompt: “generate the MCQ and answer options without additional comments.”`}
        show={false}
        exampleUrl={
          "https://chat.openai.com/share/440f589b-89bb-4346-a3d7-fe14f470257d"
        }
      />
      <div id="prompt4"></div>
      <p className="mt-6 font-light text-md text-gray-800">
        By improving the precision of our request, we exert greater control over
        the LLM’s output. The task is more specifically defined (MCQ with 5
        possible answers) and more information about the question material is
        provided (labeling components of mitochondria). If a detail or
        instruction isn’t specified, the LLM will jump to its own conclusion, so
        be precise! There is no limit to the amount of detail you can provide.
      </p>

      <CompletionExample
        title={`4. A very specific prompt`}
        prompt={`I am a 9th grade Florida biology educator teaching a unit on mitochondria. State standards require students to be able to identify 7 core components of the mitochondria (outer membrane, inner membrane, intermembrane space, matrix, mtDNA, ribosomes, proteins) on a diagram. I am preparing a multiple choice quiz that uses spatial descriptions of components (the outer membrane surrounds the inner membrane) to test whether students understand the mitochondria. Write 5 multiple choice questions, each with 5 possible answers, that test this concept. Ensure each possible answer for each question is distinct.`}
        completion={`Certainly! Here are five multiple choice questions on mitochondria, each with five distinct possible answers:
1. Which component of the mitochondria surrounds the inner membrane?
a) Matrix
b) Ribosomes
c) Intermembrane space
d) mtDNA
e) Outer membrane

***REMAINING QUESTIONS CUT OFF TO SAVE SPACE***
`}
        comment={`Open the prompt in ChatGPT to see all 5 questions.`}
        show={false}
        exampleUrl={
          "https://chat.openai.com/share/77fc0e59-9f9e-4b27-80a4-a6eb4d91fab2"
        }
      />

      <p className="mt-6 font-light text-md text-gray-800">
        There are multiple important features of this prompt. First, we specify
        that the 9th grade biology teacher is from Florida: this may or may not
        improve the relevance of the MCQ, depending on if the LLM understands
        Florida state biology standards. If you are unsure if a certain detail
        will improve the output, provide it anyway!
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        Next, we specify what details about mitochondria should be evaluated in
        the question. This is critical, as regional variations in curriculum can
        influence how a concept is understood by the model, thus how it writes
        the MCQ. Another biology curriculum might specify 10 core components of
        mitochondria to test instead of 7, hence the need for precision. Then,
        we provide further clarification on how the information should be tested
        (relating parts of a mitochondria spatially).
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        In summary, descriptive prompt writing provides the LLM with clarity on
        what to output. Precision improves both the quality of information in
        the output and your control over the output (what the result looks
        like). If an LLM is producing an output that is unfocused, imprecise, or
        incorrect, consider where in the prompt you could provide additional
        information.
      </p>

      <FooterNav pageBefore={"Strategies"} pageAfter={"Labeling"} />
    </div>
  );
}
