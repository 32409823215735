import React, { useState } from "react";
import { Breadcrumb } from "../../Components/ContentPage/ContentRendering/Breadcrumb";
import FooterNav from "../../Components/ContentPage/Menus/FooterNav";
import { useNavigate } from "react-router-dom";
import CompletionExample from "../../Components/ContentPage/ContentRendering/CompletionExample";

export default function PromptEngineering({}) {
  const navigate = useNavigate("");

  function navigateTo(page) {
    navigate(page);
  }

  return (
    <div>
      <Breadcrumb page={"Introduction"} subPage={"Prompt Engineering"} />
      <h1 className="font-semibold text-3xl mt-2">Prompt Engineering</h1>
      <p className="mt-3 font-light text-md text-gray-800">
        {" "}
        The core interaction you have with an LLM is ‘prompting’. Prompting is the action of writing and submitting text as an input to the LLM, which it uses to generate an output. Because LLMs are inherently unpredictable, prompting is an experimental process that induces an LLM to produce increasingly preferred outputs. Thus, prompt engineering describes the process of intentionally designing prompts that produce the highest quality output for your intended use case. 
      </p>
      <div id="prompt1"></div>
      <p className="mt-6 font-light text-md text-gray-800">
        Below is an example of an input (prompt) and output (completion) from ChatGPT.
      </p>

      <CompletionExample
        title={`1: Text Completion`}
        prompt={`Complete the following sentence.
        
Mary had a little lamb`}
        completion={`Mary had a little lamb, its fleece was white as snow.`}
        comment={`Your first prompt!`}
        show={false}
        exampleUrl={"https://chat.openai.com/share/bc98e76c-e3cc-4a2d-b10b-7a98732fe48f"}
      />
      <div id="prompt2"></div>
      <p className="mt-6 text-gray-700 text-md font-light">
        The LLM received a direction (Complete the sentence) and the beginning of a sentence (Mary had a little lamb) as a single input and successfully wrote the full sentence for its output. As you will practice in this guide, LLMs can handle a wide variety of inputs and produce a wide variety of useful outputs. Below is an example of the LLM accurately answering a question.
      </p>

      <CompletionExample
        title={`2: Question and Answer (Q&A)`}
        prompt={`What is the capital of Greece?`}
        completion={`The capital of Greece is Athens.`}
        comment={`To view examples in ChatGPT (using the ChatGPT icon), you must be logged in.`}
        show={false}
        exampleUrl={'https://chat.openai.com/share/2be69704-6b9b-4fcc-903a-0351f0eb1de5'}
      />
      <div id="prompt3"></div>
      <p className="mt-6 text-gray-700 text-md font-light">
        The primary behavior of an LLM is to produce accurate, understandable textual output that directly addresses the input it was provided. This is a powerful capability, which leads to the common scenario of prompting LLMs to output a type of written content, such as an essay or poem.
      </p>

      <CompletionExample
        title={`3: Write a Poem`}
        prompt={`Write a haiku about cats versus dogs.`}
        completion={`Cat’s in silent grace,\nDogs with boundless joy and play,\nBoth bring love's embrace.`}
        comment={``}
        show={false}
        exampleUrl={'https://chat.openai.com/share/3fb958ae-8a23-4ff1-910f-71b9ab90efac'}
      />
      <div id="prompt4"></div>
      <p className="mt-6 text-gray-700 text-md font-light">
        LLMs are capable of processing prompts ranging from 1000s of words to 
        <a
          href="https://www.searchenginejournal.com/anthropic-introduces-claude-2-1-with-200k-context-window/501907/"
          target="_blank"
          className="hover:cursor-pointer hover:underline text-blue-700"
          rel="noreferrer"
        > hundreds of thousands. </a>
        This is useful when prompting the model with long, complex inputs. It is also useful if you are instructing the model to complete a task such as summarizing an 
        <a
          href="https://www.edweek.org/leadership/what-a-principal-learned-from-conflict-with-his-teachers/2024/01"
          target="_blank"
          className="hover:cursor-pointer hover:underline text-blue-700"
          rel="noreferrer"
        > article</a>
        .
      </p>

      <CompletionExample
        title={`4: Summarization`}
        prompt={`***ARTICLE CONTENT***\n\nSummarize the above article in 2-3 sentences.`}
        completion={`Scott Tombleson, a former software professional turned principal of South Portland Public High School in Maine, initially struggled with conflict and decision-making challenges when he took on the role. He realized the need to involve various stakeholders in decision-making and address conflicts openly. By creating different teams within the school and embracing difficult conversations, he has become more comfortable with conflict resolution and aims to make positive changes in the school environment.`}
        comment={"Try experimenting with the length of the article summary."}
        show={false}
        exampleUrl={'https://chat.openai.com/share/a6115765-60bd-44f2-9e1f-9d9b16612a55'}
      />

      <p className="mt-6 font-light text-md text-gray-800">
        Prompt engineering is far from an exact science, hence the extensive research that is dedicated to creating effective prompting practices. As you will experience throughout this guide and in your own prompting, LLMs exhibit strange and unintuitive behavior that can sometimes be harnessed. The classic example: if you start a prompt by telling an LLM it is an expert in a certain field, it will produce higher-quality outputs than a prompt without this detail!
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        With this quick primer, you are now ready to learn more about effective prompting techniques and frameworks for approaching prompt engineering.
      </p>
      <FooterNav pageBefore={"Language Models"} pageAfter={"Strategies"} />
    </div>
  );
}
