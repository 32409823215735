import React from "react";
import { Breadcrumb } from "../../Components/ContentPage/ContentRendering/Breadcrumb";
import FooterNav from "../../Components/ContentPage/Menus/FooterNav";
import CompletionExample from "../../Components/ContentPage/ContentRendering/CompletionExample";

export default function InstructionFeedback({}) {
  return (
    <div>
      <Breadcrumb page={"Use Cases"} subPage={"Instruction Feedback"} />
      <h1 className="font-semibold text-3xl mt-2">Instruction Feedback</h1>
      <p className="mt-3 font-light text-md text-gray-800">
        Coming soon. In the meantime, if you want to request a resource, reach
        {" out "}
        <a
          href="https://www.facebook.com/groups/k12promptengineeringguide"
          target="_blank"
          className="hover:cursor-pointer hover:underline text-blue-700"
          rel="noreferrer"
        >
          {"here"}
        </a>
        .
      </p>
      {/* <p className="mt-3 font-light text-md text-gray-800">
        The design of instruction (i.e., instructional design) is a core
        educator competence that educators build and refine throughout their
        careers. Effective instructional design can be difficult for early
        career educators who are bombarded with other aspects of the profession
        such as classroom management and discipline. Difficulty with the
        planning, delivery, and assessment of instruction is often attributed as
        a reason for the high turnover of new K-12 educators{" "}
        <span className="text-blue-600 hover:cursor-pointer hover:underline">
          (research)
        </span>
        .{" "}
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        With this in mind, let’s explore how AI can be helpful in increasing an
        educator’s competence in instructional design by providing educators
        with feedback on their delivery of instructional content. Other sections
        outline how LLMs can help educators iteratively design{" "}
        <span className="text-blue-600 hover:cursor-pointer hover:underline">
          Learning Objectives
        </span>
        ,{" "}
        <span className="text-blue-600 hover:cursor-pointer hover:underline">
          Lesson Plans
        </span>
        , and
        <span className="text-blue-600 hover:cursor-pointer hover:underline">
          Assessments
        </span>
        . These articles provide a foundation for designing effective lesson
        plans. Once lesson plans are developed, LLMs can go a step further by
        providing feedback that can improve the execution of the lesson plan.
        So, in a sense, an LLM can help improve the design of instruction for
        educators.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        Like other use cases we’ve explored, the Instruction Feedback examples
        are crafted from the perspective of an instructional coach or a similar
        supporting role that is responsible to provide actionable feedback to
        educators on their lesson planning and teaching. Unfortunately, many
        educators do not have easy access to instructional coaches who can
        provide them with just-in-time or real-time feedback on lesson delivery
        <span className="text-blue-600 hover:cursor-pointer hover:underline">
          (research)
        </span>
        . However, even for educators with access to these resources, the
        reality is that 1:1 support is not available at all the times when it is
        needed. Thus, LLMs can be beneficial in providing this type of feedback.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        Let’s start with a basic example of delivery feedback: a teacher
        reflects on a lesson and the LLM reflects back in a constructive manner.
      </p>
      <CompletionExample
        title={`1: asdf`}
        prompt={`reflection`}
        completion={`output`}
        comment={``}
        show={false}
      />
      <p className="mt-6 font-light text-md text-gray-800">
        You are giving the model a single input to evaluate: your own
        reflection. While this is a good opportunity to reflect on your delivery
        and seek an additional perspective, there are other inputs that could be
        added to produce more in depth feedback from the LLM. Important
        information for the model to understand are such things as:
      </p>
      <ul className="mt-1 list-disc list-inside pl-4 font-light text-sm text-gray-800">
        <li>The learning objective(s) </li>
        <li>The entire completed lesson plan</li>
        <li>The learner assessments</li>
        <li>What you wanted to improve on or avoid in the lesson</li>
      </ul>
      <p className="mt-6 font-light text-md text-gray-800">
        You would want to share what you would if you were to get coaching from
        an instructional coach. This contextualization helps the model produce a
        more useful response.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        What are other ‘content delivery inputs’ the model could leverage to
        provide you feedback on your delivery? The next step would be to add
        text content directly from your lesson–for example, raw text from a
        lesson plan or outline, your (or a student’s) lecture notes, or even the
        text-only version of a handout or presentation{" "}
        <span className="text-blue-600 hover:cursor-pointer hover:underline">
          (PDF to text comes to mind)
        </span>
        . This content enables the model to further understand “what” about your
        lesson and lesson content to evaluate and “how” to make the feedback
        useful to your situation.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        Given a “lecture” outline and a student’s notes from the lesson, the LLM
        provides the following feedback.
      </p>
      <CompletionExample
        title={`2: asdf`}
        prompt={`lo + lesson plan + what you want to improve`}
        completion={`output`}
        comment={``}
        show={false}
      />
      <p className="mt-6 font-light text-md text-gray-800">
        Another valuable input to consider is a direct transcript of the lesson
        or presentation–either from a hybrid or remote class recording (if you
        have the proper consent to record) or a recording from a practice lesson
        outside of the classroom environment. Converting the lecture audio into
        a transcript is arguably the most valuable information you could provide
        your LLM “instructional coach.”
      </p>
      <CompletionExample
        title={`3: asdf`}
        prompt={`transcript + context compounded from above`}
        completion={`output`}
        comment={``}
        show={false}
      />
      <p className="mt-6 font-light text-md text-gray-800">
        There are multiple free, online speech-to-text (STT) services that can
        turn an audio recording into an accurate transcript. If you are
        concerned about the safety and privacy of those in a recording (in the
        case of a consented recording), we recommend reaching out to a district
        technology specialist or coordinator to discuss how to safely transcribe
        audio for use with an LLM. There are multiple enterprise solutions that
        can securely transcribe audio content and even go as far as to
        explicitly redact personally identifiable information (PII) that may
        exist in the recording.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        Although it is beyond the scope of this discussion, it is worth noting
        that STT is a rapidly improving technology that is capable of more than
        just identifying and transcribing spoken words. Increasingly, STT models
        can understand and provide important information about the context of
        the recording, the presenter’s speaking patterns (e.g., inflection,
        pace, rhythm), and other human-like annotations.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        If you are curious, see{" "}
        <span className="text-blue-600 hover:cursor-pointer hover:underline">
          OpenAI’s Whisper model
        </span>
        , which users can upload audio to and prompt with specific instructions
        (that’s right: LLMs aren’t the only ML model you can “prompt” with
        information!) An example would be uploading an audio file of a practice
        lecture you give in an empty classroom and asking the model to
        transcribe your lecture, specifically taking note of the pacing and
        depth of the content explanations. Even further out of scope (because we
        can’t help it!), consider image-to-text models (or ‘vision’ models)
        which are becoming increasingly competent at taking recorded video and
        producing a contextual understanding of the scene. For example, a model
        like this can view a recording of your lecture and comment on your
        gestures, your movement around the classroom, your use of student
        engagement, and so forth (by the way, vision models are also
        promptable). Now, imagine putting this system together with a STT
        system! Would you consider this superintelligence?
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        Additional inputs an ‘instructional design’ prompt for your LLM could
        leverage includes– notes on the teacher’s physical presentation,
        student-prepared commentary or feedback on the teacher’s presentation,
        and even the feedback of a 1:1 resource if they are present during the
        lesson.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        As a side note–for any responsible use of AI, it is critical that the
        information you provide is securely stored and privacy-adhering for all
        stakeholders.
      </p>
      <CompletionExample
        title={`4: asdf`}
        prompt={`student feedback + compounding above.`}
        completion={`output`}
        comment={``}
        show={false}
      />
      <p className="mt-6 font-light text-md text-gray-800">
        Like with all applications of LLMs, you are limited by the information
        you put in (keep in mind the phrase–garbage in, garbage out). Although
        this particular application requires inputs that may or may not be
        available, there are still multiple ways to leverage instructional
        delivery information and feedback to turn an LLM into an adjust-in-time
        instructional coach.
      </p> */}

      <FooterNav pageBefore={"Grading FRQs"} pageAfter={"Roleplaying"} />
    </div>
  );
}
