import React from "react";
import { Breadcrumb } from "../../Components/ContentPage/ContentRendering/Breadcrumb";
import FooterNav from "../../Components/ContentPage/Menus/FooterNav";

export default function Decomposition({}) {
  return (
    <div>
      <Breadcrumb page={"Strategies"} subPage={"Decomposition"} />
      <h1 className="font-semibold text-3xl mt-2">Decomposition</h1>
      <p className="mt-3 font-light text-md text-gray-800">
        A common trap is demanding a model accomplishes multiple tasks at once.
        Often, a user will ask a model to do multiple things or complete a task
        that can be broken down into smaller steps. This type of complexity is
        difficult for models to handle, as they must ‘plan’ and produce a
        response in one completion.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        For example, if you ask a model to write a quiz for a course unit, you
        are asking it to complete a relatively complex task all at once:
      </p>
      <ul className="list-disc list-inside pl-6 mt-1 font-light text-sm text-gray-800 leading-5">
        <li>Understand the different content areas to be evaluated</li>
        <li>Approximate the appropriate length and difficulty of the quiz</li>
        <li>
          Write questions that evaluate the content areas and are consistent
          with the length and the difficult
        </li>
        <li>Make additional assessment design trade-offs/improvements</li>
      </ul>
      <p className="mt-6 font-light text-md text-gray-800">
        Precision helps solve this problem to an extent, but another strategy is
        to explicitly break the task down into smaller steps. In doing so, the
        model can complete each sub-task more effectively, which improves the
        quality of each subsequent task.
      </p>
      <FooterNav pageBefore={"Examples"} pageAfter={"Contextualization"} />
    </div>
  );
}
