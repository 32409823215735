import "./App.css";

import React, { useEffect } from "react";
import posthog from "posthog-js";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Coordinator from "./Content/Pages/Coordinator";
import { useLocation } from "react-router-dom";

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  posthog.init("phc_OTdMRFcEib3AqCY2Xeub31InHJk3FkVORLeTYwOwAy3", {
    api_host: "https://us.posthog.com",
  });

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Coordinator page="home" />} />
        <Route path="/*" element={<Coordinator page="home" />} />
        <Route path="/home" element={<Coordinator page="home" />} />
        <Route path="/contact" element={<Coordinator page="contact" />} />
        <Route
          path="/introduction"
          element={<Coordinator page="introduction" />}
        />
        <Route
          path="/introduction/artificialintelligence"
          element={<Coordinator page="artificialintelligence" />}
        />
        <Route
          path="/introduction/languagemodels"
          element={<Coordinator page="languagemodels" />}
        />
        <Route
          path="/introduction/promptengineering"
          element={<Coordinator page="promptengineering" />}
        />
        <Route path="/strategies" element={<Coordinator page="strategies" />} />
        <Route
          path="/strategies/labeling"
          element={<Coordinator page="labeling" />}
        />
        <Route
          path="/strategies/examples"
          element={<Coordinator page="examples" />}
        />
        <Route
          path="/strategies/decomposition"
          element={<Coordinator page="decomposition" />}
        />
        <Route
          path="/strategies/contextualization"
          element={<Coordinator page="contextualization" />}
        />
        <Route
          path="/strategies/precision"
          element={<Coordinator page="precision" />}
        />
        <Route path="/usecases" element={<Coordinator page="usecases" />} />
        <Route path="/usecases/frqs" element={<Coordinator page="frqs" />} />
        <Route path="/usecases/mcqs" element={<Coordinator page="mcqs" />} />
        <Route
          path="/usecases/gradingfrqs"
          element={<Coordinator page="gradingfrqs" />}
        />
        <Route
          path="/usecases/gradingmcqs"
          element={<Coordinator page="gradingmcqs" />}
        />
        <Route
          path="/usecases/roleplaying"
          element={<Coordinator page="roleplaying" />}
        />
        <Route
          path="/usecases/instructionfeedback"
          element={<Coordinator page="instructionfeedback" />}
        />
        <Route
          path="/commonquestions"
          element={<Coordinator page="commonquestions" />}
        />
        <Route path="/tools" element={<Coordinator page="tools" />} />
      </Routes>
    </Router>
  );
}

export default App;
