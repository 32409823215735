import React from "react";
// import posthog from "posthog-js";
// posthog.capture("my event", { property: "value" });

// Page components
import NavBar from "../Components/ContentPage/Menus/NewNavBar";
import ContentMenu from "../Components/ContentPage/Menus/ContentMenu";
import PageOutline from "../Components/ContentPage/Menus/PageOutline";

// Pages
import Home from "./Main/Home";
import Contact from "./Main/Contact";

import Introduction from "./Introduction/Introduction";
import AI from "./Introduction/ArtificialIntelligence";
import LanguageModels from "./Introduction/LanguageModels";
import PromptEngineering from "./Introduction/PromptEngineering";

import Strategies from "./Strategies/Strategies";
import Contextualization from "./Strategies/Contextualization";
import Decomposition from "./Strategies/Decomposition";
import Examples from "./Strategies/Examples";
import Labeling from "./Strategies/Labeling";
import Precision from "./Strategies/Precision";

import UseCases from "./UseCases/UseCases";
import FRQs from "./UseCases/FRQs";
import MCQs from "./UseCases/MCQs";
import GradingFRQs from "./UseCases/GradingFRQs";
import GradingMCQs from "./UseCases/GradingMCQs";
import RolePlaying from "./UseCases/RolePlaying";
import InstructionFeedback from "./UseCases/InstructionFeedback";

import Tools from "./Tools/Tools";
import CommonQuestions from "./CommonQuestions/CommonQuestions";

export default function Coordinator({ page }) {
  function getPageComponent() {
    if (page === "home") {
      return <Home />;
    }
    if (page === "contact") {
      return <Contact />;
    }
    if (page === "precision") {
      return <Precision />;
    }
    if (page === "introduction") {
      return <Introduction />;
    }
    if (page === "artificialintelligence") {
      return <AI />;
    }
    if (page === "languagemodels") {
      return <LanguageModels />;
    }
    if (page === "promptengineering") {
      return <PromptEngineering />;
    }
    if (page === "precision") {
      return <Precision />;
    }
    if (page === "labeling") {
      return <Labeling />;
    }
    if (page === "decomposition") {
      return <Decomposition />;
    }
    if (page === "contextualization") {
      return <Contextualization />;
    }
    if (page === "examples") {
      return <Examples />;
    }
    if (page === "strategies") {
      return <Strategies />;
    }
    if (page === "tools") {
      return <Tools />;
    }
    if (page === "commonquestions") {
      return <CommonQuestions />;
    }
    if (page === "usecases") {
      return <UseCases />;
    }
    if (page === "frqs") {
      return <FRQs />;
    }
    if (page === "mcqs") {
      return <MCQs />;
    }
    if (page === "gradingfrqs") {
      return <GradingFRQs />;
    }
    if (page === "gradingmcqs") {
      return <GradingMCQs />;
    }
    if (page === "roleplaying") {
      return <RolePlaying />;
    }
    if (page === "instructionfeedback") {
      return <InstructionFeedback />;
    }
  }

  return (
    <div>
      <NavBar />
      <ContentMenu selectedPage={page} />
      <div className="mx-80 mt-32 max-[650px]:mx-10 max-[1400px]:mr-20 max-[1400px]:ml-80 max-[800px]:ml-20 duration-500 ease-in-out">
        {getPageComponent(page)}
      </div>
      <PageOutline selectedPage={page} />
    </div>
  );
}
