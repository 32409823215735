import React from "react";
import { useNavigate } from "react-router-dom";

export default function FooterNav({ pageBefore, pageAfter }) {
  const navigate = useNavigate("");
  function navigateTo(page) {
    let parsedPage = page.replace(" ", "").toLowerCase();
    let path = getPathFromName(parsedPage);
    console.log(path);
    navigate(path);
  }

  return (
    <div className="mt-10 mb-10">
      <hr className="text-gray-400 mb-10"></hr>
      <div className="flex flex-row justify-between items-center">
        <div>
          {pageBefore !== "" ? (
            <div
              className="flex flex-row justify-start items-center leading-5"
              onClick={() => navigateTo(pageBefore)}
            >
              {/* arrow */}
              <div>
                <span class="material-symbols-outlined text-gray-700 text-md mt-[6px] hover:cursor-pointer hover:text-black duration-300 ease-in-out">
                  chevron_left
                </span>
              </div>
              <h1 className="ml-2 text-md font-normal text-gray-700 hover:cursor-pointer hover:text-black duration-300 ease-in-out">
                {pageBefore}
              </h1>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div>
          {pageAfter !== "" ? (
            <div
              className="flex flex-row justify-end items-center leading-5"
              onClick={() => navigateTo(pageAfter)}
            >
              <div className="mr-2 text-md font-normal text-gray-700 hover:cursor-pointer hover:text-black duration-300 ease-in-out">
                {pageAfter}
              </div>
              <div>
                <span class="material-symbols-outlined text-gray-700 text-md mt-[6px] hover:cursor-pointer hover:text-black duration-300 ease-in-out">
                  chevron_right
                </span>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );
}

export function getPathFromName(name) {
  let path = "";

  if (name === "home") {
    path = `/home`;
  }
  if (name === "introduction") {
    path = `/introduction`;
  }
  if (
    name === "artificialintelligence" ||
    name === "languagemodels" ||
    name === "promptengineering"
  ) {
    path = `/introduction/${name}`;
  }
  if (name === "strategies") {
    path = `/strategies`;
  }
  if (
    name === "precision" ||
    name === "labeling" ||
    name === "examples" ||
    name === "decomposition" ||
    name === "contextualization"
  ) {
    path = `/strategies/${name}`;
  }

  if (name === "usecases") {
    path = `/usecases`;
  }
  if (
    name === "frqs" ||
    name === "mcqs" ||
    name === "gradingfrqs" ||
    name === "gradingmcqs" ||
    name === "instructionfeedback" ||
    name === "roleplaying"
  ) {
    path = `/usecases/${name}`;
  }

  if (name === "commonquestions") {
    path = `/commonquestions`;
  }

  if (name === "tools") {
    path = `/tools`;
  }

  return path;
}
