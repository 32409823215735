import React from "react";
import { Breadcrumb } from "../../Components/ContentPage/ContentRendering/Breadcrumb";
import FooterNav from "../../Components/ContentPage/Menus/FooterNav";
import CompletionExample from "../../Components/ContentPage/ContentRendering/CompletionExample";

export default function Examples({}) {
  return (
    <div>
      <Breadcrumb page={"Strategies"} subPage={"Examples"} />
      <h1 className="font-semibold text-3xl mt-2">Examples</h1>
      <p className="mt-3 font-light text-md text-gray-800">
        The third strategy we will explore is called examples. Put simply,
        providing an LLM with examples of expected behavior allows us to
        influence the model's behavior. As we will explore through this article,
        this on-the-fly "learning" is a useful ability.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        One way to think about LLMs and how to use them is in the context of
        “tasks”, a term we’ve mentioned in previous sections. A task is a
        specific way you want the LLM to take an input and turn it into an
        output. When you write a prompt and submit it to an LLM, you are
        defining a task that the model will attempt to understand and produce an
        appropriate output for. Popular tasks include:
      </p>
      <ul className="list-disc list-inside pl-4 font-light text-sm text-gray-800 pt-2">
        <li>
          Question and answering (Q&A) - the model reads a question and
          generates an answer
        </li>
        <li>
          Summarization - the model takes a given snippet of text and writes a
          summary
        </li>
        <li>
          Role-playing - the model acts as a persona and responds as the persona
          Information extraction
        </li>
        <li>
          Information extraction - the model “reads” (searches) for specified
          information and returns it
        </li>
        <li>Translation - the model re-writes given text in a new language</li>
      </ul>
      <div id="prompt1"></div>
      <p className="mt-6 font-light text-md text-gray-800">
        Q&A and summarization are very common tasks, but these are just one of
        many examples of tasks. The task doesn’t have to be particularly useful.
        It could be trivial, such as: take the first letter of each sentence and
        write a word with it.
      </p>
      <CompletionExample
        title={`1: Take the first letter`}
        prompt={`Hello, this is a sentence.
I am a second sentence.
This is a third sentence.

Instructions: Take the first letter of each sentence and write a word.`}
        completion={`HIT`}
        comment={`If you asked the model to do this for 20 sentences, you may need to label each one!`}
        show={false}
        exampleUrl={
          "https://chat.openai.com/share/c8c0672a-c1ba-47ec-9c30-a15560fb39c0"
        }
      />
      <p className="mt-6 font-light text-md text-gray-800">
        Prompting with ‘tasks’ is a useful way of thinking about LLMs, because
        this is how models are trained and fine-tuned. A great example of this
        is Reddit’s ‘Explain Like I’m 5’ (ELI5) subreddit, which is included in
        many LLM training datasets. Essentially, users will answer questions or
        explain concepts in simple, clear terms. There is a particular style to
        these answers that makes them a great model for anyone looking to
        receive an explanation about a topic.
      </p>
      <div id="prompt2"></div>
      <p className="mt-6 font-light text-md text-gray-800">
        If you lived in a world where Reddit did not exist or this training data
        wasn’t available, and you asked a model to take a difficult concept and
        “explain it to me like I’m 5 years old”, it would likely struggle, as it
        has not seen high-quality examples of this task. However, because LLMs
        have seen tens of thousands of examples of ELI5 questions and
        explanations, it not only understands what you want it to do, but can
        confidently produce an output modeled off of these examples.
      </p>
      <CompletionExample
        title={`2: Explain Like I'm 5 (ELI5)`}
        prompt={`ELI5: guided learning in instructional design and how it would apply to a 5th grade art class`}
        completion={`Guided learning in instructional design is like having a helpful coach or guide while you're learning something new. Imagine you're on a treasure hunt. Instead of just giving you a treasure map and saying "go find it," your guide walks with you, pointing out important landmarks and giving hints when you're unsure which way to go. That's what guided learning is about – it provides support and direction as you learn.
        
        *** REST OF RESPONSE CUT OFF TO SAVE SPACE***`}
        comment={`Do you notice there is a style to the response?`}
        show={false}
        exampleUrl={
          "https://chat.openai.com/share/4d115063-fa8e-405a-84b7-fae4dd9847ad"
        }
      />
      <p className="mt-6 font-light text-md text-gray-800">
        During the training of LLMs, this process is repeated for multiple
        classes of common tasks deemed most useful to an LLM user. You will
        likely use these tasks one or more times in your day-to-day work, and
        experience the LLM’s incredible performance, because it has been
        optimized on these tasks. This further explains the importance of
        <a
          href="https://www.k12promptguide.com/strategies/labeling"
          target="_blank"
          className="hover:cursor-pointer hover:underline text-blue-700"
          rel="noreferrer"
        >
          {" labeling."}
        </a>
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        What happens if you want an LLM to complete a novel task? Returning to
        the first example, you want the model to take the first letter of each
        sentence and write a word. If the LLM did not see examples of this task
        in its training data, it could struggle. It all depends on the
        complexity of the task. The more complex or difficult the task, the more
        likely the model will not understand how to complete it. This is where
        examples come into play.
      </p>
      <div id="prompt3"></div>
      <p className="mt-6 font-light text-md text-gray-800">
        Known as
        <a
          href="https://arxiv.org/abs/2005.14165"
          target="_blank"
          className="hover:cursor-pointer hover:underline text-blue-700"
          rel="noreferrer"
        >
          {" few-shot prompting "}
        </a>
        in the literature, providing examples of expected behavior in your
        prompt is a strategy for improving the LLM’s performance on a specific
        task. At its core, you are simply modeling a behavior to the LLM and
        asking it to repeat this for the next example. Let’s ask the model to
        complete the ‘first letter of each sentence’ task with and without an
        example.
      </p>
      <CompletionExample
        title={`3: Zero-shot prompting`}
        prompt={`Hello, this is a sentence.
I am a second sentence.
This is a third sentence.
Each of these sentences are pretty short.
A word of advice: shorten the sentence.
Can I tell you a secret?
Hello? Are you there. Ok good.
Easy as it seems, this is hard work.
Right, let's stop here.

Instructions: Above are 9 random sentences. Take the first letter of each sentence and write a word.`}
        completion={`The first letter of each sentence forms the word "HISTEARS."`}
        comment={`This is also known as ‘zero-shot’ prompting, where you provide no examples. As you can see, the LLM gets it wrong!`}
        show={false}
        exampleUrl={
          "https://chat.openai.com/share/54f7db12-028f-48d2-aae1-31573857b51b"
        }
      />
      <div id="prompt4"></div>
      <CompletionExample
        title={`4: One-shot prompting`}
        prompt={`Hello, this is a sentence.
I am a second sentence.
This is a third sentence.
Each of these sentences are pretty short.
A word of advice: shorten the sentence.
Can I tell you a secret?
Hello? Are you there. Ok good.
Easy as it seems, this is hard work.
Right, let's stop here.

Instructions: Take the first letter of each sentence and write a word.

Output: HITEACHER

The sun sets early today.
A cat sleeps on the windowsill.
Quiet streets whisper secrets.
Books line the old shelf.
Rain patters softly outside.
A clock ticks in the hall.
Coffee aroma fills the room.
Stars twinkle in the night sky.
Moonlight is the moon and light.

Instructions:  Take the first letter of each sentence and write a word.

Output:`}
        completion={`SQUAREBCM`}
        comment={`This is also known as one-shot prompting. We gave it an example and it still got it wrong! Run it multiple times and see if it gets better.`}
        show={false}
        exampleUrl={
          "https://chat.openai.com/share/3870e5d9-dbdf-45b5-8b37-27fd138ea6ba"
        }
      />
      <p className="mt-6 font-light text-md text-gray-800">
        During the training of LLMs, this process is repeated for multiple
        classes of common tasks deemed most useful to an LLM user. You will
        likely use these tasks one or more times in your day-to-day work, and
        experience the LLM’s incredible performance, because it has been
        optimized on these tasks. This further explains the importance of
        <a
          href="https://www.k12promptguide.com/strategies/labeling"
          target="_blank"
          className="hover:cursor-pointer hover:underline text-blue-700"
          rel="noreferrer"
        >
          {" labeling."}
        </a>
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        Our trivial task is not something the model understands how to do. yet
        even when we give it a model of the intended behavior (take the first
        letter of each word, create a final word), it does not successfully
        performs it. Although this is a trivial example, it demonstrates that
        despite modern LLMs impressive abilities, an unknown or misunderstood
        action will always produce poor outputs.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        So long as your prompt fits into the LLM’s{" "}
        <a
          href="https://arxiv.org/abs/2005.14165"
          target="_blank"
          className="hover:cursor-pointer hover:underline text-blue-700"
          rel="noreferrer"
        >
          {"context window"}
        </a>
        , you can provide as many examples as you would like. If there is a
        unique task you’ve written dozens of examples for, it will improve (up
        to a point) the model’s understanding of the task you are asking it to
        accomplish.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        Let’s walk through an example of a unique task with zero-shot, one-shot,
        and few-shot prompting applied. The novel task for the LLM is as
        follows: given a learning objective framework, identify the ‘behavior’
        component of a teacher’s learning objective. As you will see, modeling
        both correct completions (examples that correctly identify the behavior)
        and incorrect completions (examples that are wrong) will create a final
        prompt that robustly addresses this specific task.
      </p>
      <CompletionExample
        title={`5: Learning objective zero-shot`}
        prompt={`Instructions: Given a learning objective, identify its behavior. Use Bloom’s Taxonomy and the ABCD framework to evaluate. If the behavior is consistent with Bloom’s Taxonomy and ABCD, say it is correct. Otherwise, say it is incorrect. Do not provide explanations.
        
Learning Objective: Students will understand the importance of the water cycle in earth science.
Answer:`}
        completion={`Incorrect.`}
        comment={`It got it right with no examples! Let's add more anyway.`}
        show={false}
        exampleUrl={
          "https://chat.openai.com/share/fe96d132-5171-4191-8cdd-8bcad7cbe015"
        }
      />
      <CompletionExample
        title={`6: Learning objective one-shot`}
        prompt={`Instructions: Given a learning objective, identify its behavior. Use Bloom’s Taxonomy and the ABCD framework to evaluate. If the behavior is consistent with Bloom’s Taxonomy and ABCD, say it is correct. Otherwise, say it is incorrect. Do not provide explanations.

Learning Objective: Students will be able to describe the 7 stages of the neuron firing process with 80% precision.
Answer: The behavior is ‘describe’. This is the correct use of ‘Behavior’.
        
Learning Objective: Students will understand the importance of the water cycle in earth science.
Answer:`}
        completion={`The behavior is ‘understand’. This is incorrect use of ‘Behavior’.`}
        comment={``}
        show={false}
        exampleUrl={
          "https://chat.openai.com/share/efdc7039-1747-4611-80ad-31b2c4568301"
        }
      />
      <div id="prompt5"></div>
      <CompletionExample
        title={`7: Learning objective few-shot`}
        prompt={`Instructions: Given a learning objective, identify its behavior. Use Bloom’s Taxonomy and the ABCD framework to evaluate. If the behavior is consistent with Bloom’s Taxonomy and ABCD, say it is correct. Otherwise, say it is incorrect. Do not provide explanations.

Learning Objective: Students will be able to describe the 7 stages of the neuron firing process with 80% precision.
Answer: The behavior is ‘describe’. This is the correct use of ‘Behavior’.

Learning Objective: Students will be able to complete a free-throw in 5 attempts.
Answer: Incorrect.
        
Learning Objective: Students will understand the importance of the water cycle in earth science.
Answer:`}
        completion={`Incorrect.`}
        comment={`If you are asking the LLM to make some binary judgement (correct/incorrect, yes/no), provide examples of each to be comprehensive.`}
        show={false}
        exampleUrl={
          "https://chat.openai.com/share/8703c65c-4303-4e2a-a037-6a47f97943dd"
        }
      />
      <p className="mt-6 font-light text-md text-gray-800">
        It has been our experience that most K-12 educator ‘tasks’ can be
        decently addressed by LLMs out-of-the-box. For getting started with
        using AI in your classroom and understanding what models can and cannot
        do, LLMs are quite comprehensive. It’s once you’ve become familiar with
        using models that providing examples helps you explore new applications
        of LLMs.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        We’ve framed our discussion of providing examples as most useful in the
        context of novel tasks or prompts the model may not understand. The
        value of modeling behavior with examples, however, also extends to
        improve an LLM’s performance on tasks it is familiar with. Often,
        well-established tasks such a Q&A and summarization have multiple
        different flavors. Returning once more to the importance of
        <a
          href="https://www.k12promptguide.com/strategies/precision"
          target="_blank"
          className="hover:cursor-pointer hover:underline text-blue-700"
          rel="noreferrer"
        >
          {" precision "}
        </a>
        in prompts, asking a model to summarize text specifies the task you want
        it to complete: summarization. However, summarization can take many
        forms. In fact, if you’ve explored this before in ChatGPT or other LLMs,
        you will find that LLMs summarization capabilities often leave out key
        phrases and explanations, which make it somewhat useless in an
        educational context where details matter.
      </p>
      <div id="prompt6"></div>
      <p className="mt-6 font-light text-md text-gray-800">
        If you provide examples of summarization that demonstrate prioritizing
        retaining key words and original snippets of text in the summary, the
        model catches on to this “flavor” of summary and will answer
        appropriately. Let's also make this type of summary very concise.
      </p>
      <CompletionExample
        title={`9: Summarization few-shot`}
        prompt={`Instructions: Summarize the paragraph very concisely, retaining key words.
        
Paragraph: The Great Wall of China, one of the greatest wonders of the world, was listed as a World Heritage by UNESCO in 1987. Just like a gigantic dragon, the Great Wall winds up and down across deserts, grasslands, mountains and plateaus, stretching approximately 21,196 kilometers from east to west of China. With a history of more than 2,300 years, some of the sections are now in ruins or have disappeared. However, it is still one of the most appealing attractions all around the world owing to its architectural grandeur and historical significance.

Summary: The 2,300 year old Great Wall of China, stretching about 21,196 kilometers and resembling a dragon across varied terrains, was recognized as a World Heritage site by UNESCO in 1987.

Paragraph: Photosynthesis is a process used by plants and other organisms to convert light energy, usually from the sun, into chemical energy that can be later used to fuel the organism's activities. This process involves the absorption of carbon dioxide, water, and light to produce glucose and oxygen. The simplicity of the overview of this process belies the complex series of chemical reactions that actually take place. This process is critical for the survival of life on Earth, as it provides the oxygen we breathe and the food we eat.

Summary: Photosynthesis, essential for life on Earth, is a process where plants convert sunlight into chemical energy, producing glucose and oxygen from carbon dioxide, water, and light.

Paragraph: The word epistemology is derived from the Greek words episteme, meaning “knowledge,” and logos, meaning “explanation” and translated in suffix form (-logia) as “the study of.” Hence, epistemology is the study of knowledge. Epistemology focuses on what knowledge is as well as what types of knowledge there are. Because knowledge is a complex concept, epistemology also includes the study of the possibility of justification, the sources and nature of justification, the sources of beliefs, and the nature of truth.

Summary:`}
        completion={`Epistemology, derived from Greek terms meaning "knowledge" and "explanation," is the study of knowledge, its types, sources, justification, and the nature of truth.`}
        comment={`This is now consistent with the style you modeled with examples.`}
        show={false}
        exampleUrl={
          "https://chat.openai.com/share/91a1cbe3-231c-43e5-8db0-e73859209239"
        }
      />
      <div id="prompt7"></div>
      <p className="mt-6 font-light text-md text-gray-800">
        Another example: if you allow students to use AI in your class (maybe
        you instruct them to use specific prompts when asking ChatGPT for help
        explaining a concept or clarifying a homework problem), drafting
        examples of appropriate questions and answers for the prompt will enable
        the model to align with the type of answer you wish to provide. Maybe
        you even take written/verbal explanations you’ve given in the past so it
        mirrors how you give explanations.
      </p>
      <CompletionExample
        title={`10: At-home explanations`}
        prompt={`Example Question: "Can you explain how photosynthesis works in plants?"

Example Response: "Certainly. Photosynthesis, an exquisite and pivotal biochemical process, transpires predominantly within plant chloroplasts. Fundamentally, it encompasses two sequential phases: the light-dependent reactions and the Calvin cycle. During the former, solar radiation catalyzes the photolysis of water, yielding oxygen as a byproduct. Concurrently, light energy is harnessed to synthesize adenosine triphosphate (ATP) and nicotinamide adenine dinucleotide phosphate (NADPH). Subsequently, the Calvin cycle, a light-independent process, utilizes ATP and NADPH to assimilate atmospheric carbon dioxide, culminating in the generation of glucose. This intricate orchestration not only fuels plant growth but also sustains terrestrial life by replenishing atmospheric oxygen and forming the foundation of food chains."

Instructions: You are an educator helping my students with their homework problems for a 11th grade biology class. Using the question and response example as a reference, answer students questions with a similar style.

Student Question: What part of photosynthesis explains plants being green?`}
        completion={`Certainly, the characteristic green hue of plants is intricately tied to the process of photosynthesis, specifically through a pigment known as chlorophyll. Residing predominantly within the chloroplasts of plant cells, chlorophyll plays a central role in absorbing sunlight, the crucial energy source for photosynthesis.

Chlorophyll molecules primarily absorb light in the blue and red wavelengths, reflecting green light, which is why plants appear green to our eyes. This reflection is not merely incidental but is a result of the specific light absorption spectrum of chlorophyll. Among the various types of chlorophyll, chlorophyll-a is the most prevalent and significant in the photosynthetic process.

*** CUT OFF FOR SPACE ***`}
        comment={``}
        show={false}
        exampleUrl={
          "https://chat.openai.com/share/e8fd4bfc-98d2-4875-871f-07495b6e1811"
        }
      />
      <p className="mt-6 font-light text-md text-gray-800">
        A final word on examples. If an LLM is performing poorly on the task you
        ask it to complete, this is a signal that providing additional examples
        of context could help. If an LLM is simply misunderstanding your prompt
        and not performing the correct task, this is an even stronger signal
        that a quick example or two could lead to an improvement.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        Tasks are a useful way of thinking about LLMs and examples are a
        strategy for defining and clarifying the task(s) you intend for the
        model to complete.
      </p>

      <FooterNav pageBefore={"Labeling"} pageAfter={"Contextualization"} />
    </div>
  );
}
