import React from "react";
import { Breadcrumb } from "../../Components/ContentPage/ContentRendering/Breadcrumb";

export default function Contact({}) {
  return (
    <div>
      <Breadcrumb page={"Contact"} subPage={""} />
      <h1 className="font-semibold text-3xl mt-2">Contact</h1>
      <p className="mt-3 font-light text-md text-gray-800">
        Join our{" "}
        <a
          href="https://www.facebook.com/groups/k12promptengineeringguide"
          target="_blank"
          className="hover:cursor-pointer hover:underline text-blue-700"
          rel="noreferrer"
        >
          {"Facebook group"}
        </a>{" "}
        for questions, comments, and inquiries about K-12 Prompt Engineering
        Guide.
      </p>
      <p className="mt-3 font-light text-md text-gray-800">
        <span className="">For general inquiries:</span>{" "}
        <a
          href="mailto:sam@classroomgpt.app"
          target="_blank"
          className="hover:cursor-pointer hover:underline text-blue-700"
          rel="noreferrer"
        >
          {"sam@classroomgpt.app"}
        </a>
      </p>
    </div>
  );
}
