import React from "react";
import { Breadcrumb } from "../../Components/ContentPage/ContentRendering/Breadcrumb";
import FooterNav from "../../Components/ContentPage/Menus/FooterNav";

export default function Introduction({}) {
  return (
    <div className="">
      <Breadcrumb page={"Introduction"} subPage={""} />
      <h1 className="font-semibold text-3xl mt-2">Introduction</h1>
      <p className="mt-3 font-light text-md text-gray-800">
        The primary purpose of the articles in this section is to clarify key
        concepts and terms commonly used in the discussion of platforms like
        ChatGPT. This section provides an overview of Artificial Intelligence
        (AI), Language Models, and Large Language Models (LLMs). Additionally, a
        conceptual framework for how these technologies work is provided.{" "}
      </p>
      {/* <p className="mt-6 font-light text-md text-gray-800">
        By the end of this section, you will be able to:
      </p> */}
      {/* <ul className="mt-1 list-disc list-inside pl-4 font-light text-sm text-gray-800">
        <li>Define Artificial Intelligence (AI)</li>
        <li>Contrast AI with Machine Learning (ML)</li>
        <li>Describe common uses of AI</li>
        <li>Describe what language modeling is</li>
        <li>Define prompt engineering</li>
        <li>Outline how prompt engineering works</li>
      </ul> */}
      {/* not sure I like this */}

      <FooterNav pageBefore={"Home"} pageAfter={"Artificial Intelligence"} />
    </div>
  );
}
