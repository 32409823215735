import React from "react";
import { Breadcrumb } from "../../Components/ContentPage/ContentRendering/Breadcrumb";
import FooterNav from "../../Components/ContentPage/Menus/FooterNav";
import CompletionExample from "../../Components/ContentPage/ContentRendering/CompletionExample";

export default function RolePlaying({}) {
  return (
    <div>
      <Breadcrumb page={"Use Cases"} subPage={"Role Playing"} />
      <h1 className="font-semibold text-3xl mt-2">Role Playing</h1>
      <p className="mt-3 font-light text-md text-gray-800">
        Coming soon. In the meantime, if you want to request a resource, reach
        {" out "}
        <a
          href="https://www.facebook.com/groups/k12promptengineeringguide"
          target="_blank"
          className="hover:cursor-pointer hover:underline text-blue-700"
          rel="noreferrer"
        >
          {"here"}
        </a>
        .
      </p>
      {/* <p className="mt-3 font-light text-md text-gray-800">
        {" "}
        A commonly overlooked capability of LLMs is their ability to ‘role play’
        people, characters, and personas. An LLM, in the simplest of terms, is a
        highly capable text generator. Therefore, given a set of instructions on
        how to behave, a model will generate text from the perspective of a
        person, place, or thing.
      </p>
      <CompletionExample
        title={`1: asdf`}
        prompt={`You are Abraham Lincoln and I am a university student. Answer my questions from the perspective of Abraham Lincoln.`}
        completion={`output`}
        comment={``}
        show={false}
      />
      <CompletionExample
        title={`1: asdf`}
        prompt={`What do you hope is the result of the Emancipation Proclamation on the Confederacy’s increasing hostility?`}
        completion={`output`}
        comment={``}
        show={false}
      />
      <p className="mt-6 font-light text-md text-gray-800">
        {" "}
        In this simple example, the model used its biographical knowledge of
        Abraham Lincoln (i.e., personality traits, life events, attitudes) to
        roleplay as Honest Abe. For social studies teachers, if you do not agree
        with the response “Abe” generated given this basic prompt, see
        <span className="text-blue-600 hover:cursor-pointer hover:underline">
          (Contextualization)
        </span>{" "}
        to learn how you can provide textbook or lecture content to align the
        model’s response with your curriculum.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        {" "}
        This isn’t just a quirky novelty. Role playing is a research-backed form
        of pedagogy that immerses the individual in valuable practice{" "}
        <span className="text-blue-600 hover:cursor-pointer hover:underline">
          (Harvard Role Play)
        </span>
        . Role playing enables a student to practice existing hard skills and
        develop new soft skills in scenarios that mirror realistic environments.
        Language learning is a sound example of a field of study where the
        benefits of exposure through role playing are undeniable{" "}
        <span className="text-blue-600 hover:cursor-pointer hover:underline">
          (Role play in LL)
        </span>
        .
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        {" "}
        Role-playing has the capability to assist with two types of
        learning–hard skills and soft skills. In the hard skill context,
        role-playing can effectively evaluate a student’s factual or technical
        comprehension of a topic at varying levels of specificity. In the soft
        skill context, role-playing can evaluate a student’s situational,
        interpersonal, or social-emotional competencies in varying situations.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        {" "}
        To address and evaluate a student’s hard skills, examine the example
        below of a ‘study buddy’ prompt that students can use to prepare for an
        upcoming unit quiz.
      </p>
      <CompletionExample
        title={`2: asdf`}
        prompt={`context + student question`}
        completion={`answer`}
        comment={``}
        show={false}
      />
      <p className="italic text-center font-light text-md text-gray-800">
        Once you’ve designed the initial context design, the model will
        understand what concepts to evaluate the student on and the “types” of
        explanations it should give. This is particularly useful for STEM
        educators.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        {" "}
        Role-playing can be used to evaluate students’ soft skills. Continuing
        with the first example of Abraham Lincoln’s persona, students could
        interview Abe by asking questions about his upbringing and political
        career. How interesting would this be for students to learn about
        Abraham Lincoln and the mid-19th century thought by asking him about
        what he would think about events or policies today?
      </p>
      <CompletionExample
        title={`3: asdf`}
        prompt={`more abe lincoln context + student question`}
        completion={`answer`}
        comment={``}
        show={false}
      />
      <p className="italic text-center font-light text-md text-gray-800">
        As noted, see{" "}
        <span className="text-blue-600 hover:cursor-pointer hover:underline">
          (Contextualization)
        </span>{" "}
        to learn more about designing prompts that align with the specific
        context and explanation presented in your class.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        {" "}
        If you are looking to begin integrating ChatGPT into the classroom,
        consider preparing a role-playing prompt and share it with your students
        using ChatGPT’s share feature (this is what you see when you click on
        the ChatGPT icon below). If your school or district uses another service
        such as Google Bard or Anthropic Claude, instruct students to copy the
        prompt into a new chat session and go from there!
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        {" "}
        Another way to leverage role-playing is in your own education as a K-12
        stakeholder. Similar to how students would use it, you can use LLMs to
        evaluate your own knowledge on a topic, such as instructional design
        principles to identify gaps in your understanding.
      </p>
      <CompletionExample
        title={`4: asdf`}
        prompt={`you are my mother - explain blooms taxonomy`}
        completion={`answer`}
        comment={``}
        show={false}
      />
      <p className="italic text-center font-light text-md text-gray-800">
        If you can’t explain it to your anthropomorphized AI mother, do you
        really understand it?
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        As you know, teaching isn’t just about knowledge. Role-playing can be
        used to practice your soft skills. This could be accomplished with
        relatively generic personas such as:
      </p>
      <ul className="mt-1 list-disc list-inside pl-4 font-light text-sm text-gray-800">
        <li>Disruptive students in the classroom</li>
        <li>New-to-the-profession educator asking for practical advice</li>
        <li>Career administrators who want to give you “helpful” feedback</li>
        <li>Career educators who don’t want to listen to your feedback </li>
        <li>“Motivated” parents who are upset with their child’s grade</li>
      </ul>

      <p className="mt-6 font-light text-md text-gray-800">
        Highly specific personas based on a relevant need could also be defined
        and used:
      </p>
      <ul className="mt-1 list-disc list-inside pl-4 font-light text-sm text-gray-800">
        <li>
          I am a 9th grade honors biology teacher. My class is made up of
          intelligent and precocious students who often become disruptive when
          material is “too slow”. Usually 20 minutes into a lesson, I encounter
          one or two students who begin talking, which ends up derailing the
          lesson…
        </li>
      </ul>
      <CompletionExample
        title={`5: asdf`}
        prompt={`you are a parent`}
        completion={`answer`}
        comment={``}
        show={false}
      />
      <p className="italic text-center font-light text-md text-gray-800">
        This conversation may benefit from additional context about the specific
        situation.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        {" "}
        The persona an LLM takes on is not limited to historical or imaginary
        humans. Try asking an LLM to act as an animal, a place, a historical
        event, or even an idea. As a rule of thumb, role-playing can be used to
        effectively instruct and evaluate understanding on almost any topic a
        lesson plan covers. See{" "}
        <span className="hover:cursor-pointer underline">(Assessment)</span> for
        ideas on using role-playing to assess students.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        {" "}
        For the technically inclined, a particularly interesting personality
        LLMs take on is the ‘persona’ of a computer or file system!{" "}
        <span className="text-blue-600 hover:cursor-pointer hover:underline">
          (terminal demo)
        </span>
        . Even if you aren’t technical, the point is the same–you can ask an LLM
        to be just about anything and it will become a valuable learning tool
        for you and your students.
      </p> */}
      <FooterNav
        pageBefore={"Instruction Feedback"}
        pageAfter={"Common Questions"}
      />
    </div>
  );
}
