import React from "react";
import { Breadcrumb } from "../../Components/ContentPage/ContentRendering/Breadcrumb";
import FooterNav from "../../Components/ContentPage/Menus/FooterNav";

export default function Strategies({}) {
  return (
    <div>
      <Breadcrumb page={"Strategies"} subPage={""} />
      <h1 className="font-semibold text-3xl mt-2">Strategies</h1>
      <p className="mt-3 font-light text-md text-gray-800">
        This section explores five general strategies for building and iterating
        on LLM prompts. Each strategy presents a research-backed framework for
        improving the quality of model outputs, in addition to specific
        techniques and prompt templates. These strategies are application and
        LLM-agnostic, so you can apply them to any context requiring you to
        draft a natural language prompt.
      </p>
      {/* (precision, labeling, examples, decomposition, and contextualization) */}
      {/* <p className="mt-6 font-light text-md text-gray-800">
        In exploring each strategy, you will learn more about the technical
        details of LLMs and common research methodologies used to evaluate and
        improve prompt engineering. You will be equipped with a toolbox of
        techniques that will make future prompting systematic and effortless.
      </p> */}
      <FooterNav pageBefore={"Prompt Engineering"} pageAfter={"Precision"} />
    </div>
  );
}
