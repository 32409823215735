import React from "react";
import { Breadcrumb } from "../../Components/ContentPage/ContentRendering/Breadcrumb";
import FooterNav from "../../Components/ContentPage/Menus/FooterNav";

export default function AI({}) {

  return (
    <div>
      <Breadcrumb page={"Introduction"} subPage={"Artificial Intelligence"} />

      <h1 className="font-semibold text-3xl mt-2" id="">
        Artifical Intelligence (AI)
      </h1>

      <p className="mt-3 font-light text-md text-gray-800">
        Artificial intelligence (AI) is broadly defined as any technology that attempts to recreate human intelligence or behavior. Ideally, this reproduced intelligence is useful, affordable, and safe for humans.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        Different types of AI aim to recreate different human behaviors or intelligences. Robotaxis, such as Waymo and Zoox, develop computer vision systems 

          <a
          href="https://plainsight.ai/blog/autonomous-vehicles-computer-vision/"
          target="_blank"
          className="hover:cursor-pointer hover:underline text-blue-700"
          rel="noreferrer"
        > (recreating sight) </a>
        to power the navigation of autonomous vehicles. Apple and Amazon develop assistants, such as Siri and Alexa, which use speech recognition
          <a
          href="https://machinelearning.apple.com/research/hey-siri"
          target="_blank"
          className="hover:cursor-pointer hover:underline text-blue-700"
          rel="noreferrer"
        > (recreating hearing) </a>
         to understand what you are saying. More recently, companies like OpenAI are developing systems like ChatGPT that understand language
          <a
          href="https://chat.openai.com/"
          target="_blank"
          className="hover:cursor-pointer hover:underline text-blue-700"
          rel="noreferrer"
        > (recreating cognition) </a>
         and can produce language itself.
      </p>
      {/* <div className="mt-10 flex flex-row justify-center items-center">
        <img
          className=" w-96 h-auto rounded-lg"
          src="https://i.imgur.com/4Uj91bl.jpg"
        ></img>
      </div> */}
      <p className="mt-6 font-light text-md text-gray-800">
        AI is used interchangeably with machine learning (ML), but they are distinct concepts. AI is a catch-all term for any technology aiming to reproduce human intelligence, while ML is a field of techniques and methodologies used by engineers to develop these intelligent systems. There exist many different ML technologies and architectures to build AI, because different types of human intelligence require different techniques to properly model them. Tesla Autopilot, Alexa, and ChatGPT each use unique approaches to model their versions of intelligence. A way to remember the distinction: we use ML to build AIs.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        AI is a prolific force that impacts much of our day-to-day lives. From scrolling social media to submitting a loan application, you encounter and use AI multiple times a day across multiple scenarios. You are usually unaware that a product or service uses AI, which is often a useful abstraction that keeps your task straightforward. When you take a picture of a check and submit it via a bank’s mobile app, the bank uses computer vision (AI) to process the deposit. It matters that the bank quickly and accurately deposits your money, not that they used AI!
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        Other systems powered by AI that you likely use daily include:
      </p>
      <ul className="list-disc list-inside pl-4 font-light text-sm text-gray-800">
        <li>iPhone Spell check</li>
        <li>Gmail spam filter</li>
        <li>Netflix content recommendations</li>
        <li>GPS navigation</li>
      </ul>
      <p className="mt-6 font-light text-md text-gray-800">
        With the introduction of a system like ChatGPT, AI is much more in your face. While many products and services are powered by AI (unbeknownst to you), ChatGPT is the product, which means you are interacting with the fundamental technology much closer than ever before. This presents a new set of challenges to learn and use a new product, as AI is random and unpredictable. In this guide, you will learn to harness AIs such as ChatGPT to reduce unpredictability and improve its usefulness in the classroom.
      </p>
      <FooterNav pageBefore={"Introduction"} pageAfter={"Language Models"} />
    </div>
  );
}
