import React from "react";
import { Breadcrumb } from "../../Components/ContentPage/ContentRendering/Breadcrumb";
import FooterNav from "../../Components/ContentPage/Menus/FooterNav";
import CompletionExample from "../../Components/ContentPage/ContentRendering/CompletionExample";

export default function FRQs({}) {
  return (
    <div>
      <Breadcrumb page={"Use Cases"} subPage={"FRQs"} />
      <h1 className="font-semibold text-3xl mt-2">FRQs</h1>
      <p className="mt-3 font-normal text-xl scroll-mt-24" id="problems">
        The current problem(s) with FRQs
      </p>
      <p className="mt-3 font-light text-md text-gray-800">
        Writing high-quality questions for assessments is a difficult task that
        is non-unique to MCQs. Free-response questions (FRQs) is a distinct area
        of question writing that has its own difficulties and challenges. FRQs
        require students to construct their own answers rather than selecting
        from provided options, demanding a deeper understanding and application
        of knowledge. This format can offer richer insights into student
        comprehension and critical thinking skills. However, crafting FRQs that
        are clear, focused, and capable of eliciting the desired depth of
        response is no small feat. Educators must carefully balance the
        question's complexity, ensuring it is accessible yet challenging, and
        aligns with the learning objectives. The task involves defining the
        scope of acceptable answers, anticipating potential misconceptions, and
        creating a rubric for consistent grading. Given these complexities,
        educators might resort to reusing questions, limiting the diversity of
        assessment, or may even shy away from FRQs altogether, despite their
        potential for deeper learning assessment.
      </p>
      <p className="mt-9 font-normal text-xl scroll-mt-24" id="llmshelp">
        How LLMs can help you write FRQs
      </p>
      <p className="mt-3 font-light text-md text-gray-800">
        Similarly to MCQs, LLMs can help you draft and iterate on FRQs. LLMs can
        assist in generating initial question prompts based on specific content
        areas or learning objectives, providing a starting point that educators
        can refine to suit their specific needs. Furthermore, LLMs can suggest a
        range of potential student responses, helping educators anticipate the
        variety of answers that might be submitted. This can be particularly
        useful in developing rubrics that accurately and fairly assess the depth
        and accuracy of student responses. By automating the initial drafting
        and ideation process, LLMs allow educators to focus on fine-tuning
        questions to better assess critical thinking and understanding, making
        the creation of effective FRQs less time-consuming and more accessible.
      </p>
      <p className="mt-9 font-normal text-xl scroll-mt-24" id="experiment">
        Experiment with prompts for FRQs
      </p>
      <p className="mt-3 font-light text-md text-gray-800">
        First, let’s start by designing a basic FRQ. Given a content area and
        brief description of the FRQ’s purpose, the LLM takes the lead and
        produces a quality question.
      </p>
      <CompletionExample
        title={`1: Basic FRQ`}
        prompt={`Write an FRQ (prompt and question) for an 6th grade American History student on the Vietnam War.`}
        completion={`FRQ Prompt: The Vietnam War
The Vietnam War was a long and complex conflict that lasted from 1955 to 1975, involving the North Vietnamese government and its allies in the South, known as the Viet Cong, against South Vietnam and its principal ally, the United States. The war is notable for its impact on the people of Vietnam, the soldiers who fought, and the civilians in other countries, especially the United States. The conflict was part of a larger regional conflict as well as a manifestation of the Cold War between the United States and the Soviet Union.

The war had significant effects on American society. It led to widespread protests across the United States, challenging the country's involvement in Vietnam and contributing to a broader movement questioning government decisions and advocating for peace and civil rights. The war also had a lasting impact on American military, political, and social history, shaping how future generations would view conflict and the role of the United States in world affairs.

FRQ Question:
After reading the prompt about the Vietnam War, think about the reasons behind the United States' involvement in the conflict and the impact it had both domestically within the United States and internationally. Consider the perspectives of different groups involved, including American soldiers, Vietnamese civilians, and protesters in the United States.

Write a response that addresses the following points:

Reasons for US Involvement: Explain why the United States became involved in the Vietnam War. Consider the geopolitical and ideological motivations behind the US decision to enter the conflict.

Impact on American Society: Discuss how the Vietnam War affected American society during the 1960s and 1970s. Focus on the anti-war movement, public opinion, and how the war influenced American politics and culture.

Lessons Learned: Reflect on what lessons can be learned from the Vietnam War regarding the use of military power in foreign conflicts and the importance of understanding other cultures and political systems.

Your response should demonstrate an understanding of the complexities of the Vietnam War and its long-term consequences. Use evidence from the prompt and any other sources you have studied to support your answers.`}
        comment={`Different types of FRQs with different focuses are comparably understood
        and producible by the LLM. An important differentiator between FRQs is
        length. Expecting a phrase, versus 1-2 sentences, short answer, or essay
        impacts the writing and framing of the question. `}
        show={false}
        exampleUrl={
          "https://chat.openai.com/share/9dd0ebac-7735-4557-97f7-cf0153546449"
        }
      />
      <CompletionExample
        title={`2: Specific length FRQ`}
        prompt={`I am teaching a 6th grade US History class about the impacts of the Vietnam War on popular support for foreign conflict in the United states. Write 3 FRQ prompts about this topic for my students to answer. The first prompt should be answerable in 1-2 sentence. The second prompt should be answerable in 1 paragraph. The third prompt should be answerable in a 5 paragraph essay. Each prompt should test a different topic area.`}
        completion={`FRQ Prompt 1: Public Opinion and Media (Short Answer)
Prompt: How did televised coverage of the Vietnam War influence American public opinion about the war? (Answer in 1-2 sentences)

This prompt encourages students to think about the role of media in shaping public perceptions and opinions about the Vietnam War, highlighting the impact of visual media on public sentiment.

FRQ Prompt 2: Policy and Protest (Paragraph Answer)
Prompt: Discuss the relationship between the anti-war protests during the Vietnam War and changes in U.S. foreign policy. Consider the government's response to public dissent and how it influenced decisions about military engagement. (Answer in 1 paragraph)

This prompt asks students to explore the dynamic between public protest movements and their influence on government policy, particularly in the context of U.S. foreign engagements. It encourages students to think critically about the power of civic engagement and protest in democratic societies.

FRQ Prompt 3: Long-term Impacts on Foreign Policy (Essay Answer)
Prompt: Analyze the long-term impacts of the Vietnam War on American attitudes towards foreign conflict and military intervention. In your essay, address the following points:

The initial public support for the Vietnam War and the reasons behind it.
How and why public opinion shifted during the course of the war.
The role of the anti-war movement in shaping discourse around military intervention.
The impact of the Vietnam War on subsequent U.S. foreign policy decisions, including military engagements.
The lasting effects on American society and its views on the role of the United States in international conflicts. (Answer in a 5-paragraph essay)`}
        show={false}
        exampleUrl={
          "https://chat.openai.com/share/fef61662-365f-495b-be48-ef62af92f9a5"
        }
      />
      <p className="mt-6 font-light text-md text-gray-800">
        Another differentiator when designing prompts for creating FRQs is the
        type of knowledge the question probes and the type of response the
        student should provide. For example, a ‘comparative analysis’ FRQ will
        look different than ‘case study’ or ‘novel argument’ FRQs. It is likely
        a question asking students to comparatively analyze different documents
        will require unique context to ensure the FRQ is highly specific to the
        content. Whereas a case study assumes the student has already learned
        about the person/place; therefore, the FRQ itself will not (necessarily)
        need additional sources.
      </p>
      <CompletionExample
        title={`3: Case study FRQ`}
        prompt={`I am teaching a 6th grade US History class about the impacts of the Vietnam War on popular support for foreign conflict in the United states. Write a 'case study' FRQ on the Battle of Khe Sanh that asks the students to evaluate the impact of this specific event on American sentiment and support for the conflict.`}
        completion={`
Case Study FRQ: The Battle of Khe Sanh and Its Impact on American Sentiment
Background:
The Battle of Khe Sanh was one of the most significant and controversial engagements of the Vietnam War. It took place from January 21 to July 9, 1968, involving U.S. Marines and North Vietnamese forces. The battle occurred in the Khe Sanh area in northwest South Vietnam, near the Demilitarized Zone (DMZ) and the borders of Laos and North Vietnam. This engagement was part of a larger strategic plan by North Vietnam, coinciding with the Tet Offensive, and aimed to draw U.S. forces away from major cities and attack a key American base.

Question:
Evaluate the impact of the Battle of Khe Sanh on American sentiment and support for the conflict in Vietnam. In your response, consider the following:

Media Coverage: Describe how the battle was reported in the United States and the role of media coverage in shaping public opinion about the Vietnam War. Consider the types of images, stories, and narratives that were shared with the American public.

Public Reaction: Analyze the reaction of the American public to the Battle of Khe Sanh. How did the length, casualties, and perceived significance of the battle influence American sentiment towards the war?

Government Response: Examine the U.S. government's response to the Battle of Khe Sanh and its impact on American support for the war. How did the government's portrayal of the battle align or conflict with media reports and public perception?

Long-term Effects: Discuss the long-term effects of the Battle of Khe Sanh on American attitudes towards foreign conflict. Consider how this battle, along with other events from the Vietnam War, may have influenced the United States' approach to foreign policy and military engagement in subsequent years.

Instructions:

Use evidence from class readings, discussions, and any additional research to support your analysis.
Make sure to address all parts of the question in your response.
Your response should be clear, organized, and well-written, demonstrating your understanding of the event and its broader implications.
Evaluation Criteria:
Your response will be evaluated based on the following:

Comprehension: Demonstrates a thorough understanding of the Battle of Khe Sanh and its significance.
Evidence: Uses specific examples and details from the battle and its coverage to support arguments.
Analysis: Effectively analyzes the impact of the Battle of Khe Sanh on American sentiment and support for the Vietnam War.
Clarity and Organization: Presents ideas clearly and logically, making your response easy to follow.
`}
        comment={``}
        show={false}
        exampleUrl={
          "https://chat.openai.com/share/1d6f71a9-7571-4cc3-a3b2-d8cbba22ceb8"
        }
      />
      <p className="mt-6 font-light text-md text-gray-800">
        We will discuss improving FRQs with contextualization next, but this is
        always an important strategy to keep in mind when considering different
        variations of tasks you delegate to an LLM. This prompt may benefit from
        including additional context about the topic the student is writing the
        case study on. Other one-off ways to improve your FRQ may include:
        guidelines on what to emphasize/de-emphasize, stylistic concerns about
        the prompt, or including a current event as an approachable introduction
        to the question.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        A major improvement we can make to these FRQs is including source
        material and course guidelines to steer the LLM towards a
        course-specific question. Some use cases of LLMs can rely on the model’s
        implicit knowledge of the world to produce text. Other use cases,
        including most if not all in education, cannot merely rely on the
        model’s existing knowledge to produce outputs specific to your context.
        This is true for MCQs but even more consequential for FRQs.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        For example, let’s say you want to generate a FRQ that explicitly
        requires the student to use primary sources. This is lengthy but
        important context to include (and it makes a noticeable difference in
        the question’s framing).
      </p>
      {/* <CompletionExample
        title={`4: asdf`}
        prompt={`carrying over above example with documents + textbook content`}
        completion={`better answer`}
        comment={``}
        show={false}
      /> */}
      <p className="mt-6 font-light text-md text-gray-800">
        If it is not a document-based question (DBQ), it would still benefit to
        include excerpts from your class materials and readings about the topic
        to further contextualize your intent for the FRQ to the prompt. As we
        saw in the MCQ example, grounding the model in lesson content not only
        ‘reminds’ it of the key topics, but helps it understand through what
        lens to approach the given task. If you ask an LLM to write an FRQ about
        the Civil Rights Movement and you provide lesson content that focuses on
        the strategic contributions of a particular organization, the model can
        ‘narrow’ its scope to more important details in your situation.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        Another form of context underexplored in MCQs is the inclusion of
        learning objectives, lesson plans, and general course guidelines in
        writing FRQs. Designing and delivering assessments is a core component
        of lesson planning, directly informed by your learning objective and
        lesson plan. This is important context, not just for aligning the model
        to what you are looking to evaluate with the FRQ, but holding you
        accountable to writing a question that is consistent with your initial
        lesson plan (a grave yet common mistake).
      </p>
      <CompletionExample
        title={`4: Course context for FRQ`}
        prompt={`Learning objective: By the end of this lesson, 6th-grade students will be able to explain, both orally and in writing, how significant battles of the Vietnam War (such as the Battle of Khe Sanh) catalyzed popular anti-war sentiment in the United States, by using evidence gathered from primary source documents and documentary clips to identify changes in public opinion and media coverage.

High-level goals and rubric:

1. Critical Understanding of Historical Impact: Students should gain a comprehensive understanding of how the Vietnam War influenced American society, politics, and culture. This includes recognizing the complexity of the war's impact on American public opinion and the role of media and public demonstrations in shaping the national conversation about the war.

2. Analytical Skills in Evaluating Sources: Students should develop the ability to critically analyze and evaluate primary and secondary sources. They will learn to distinguish between different types of sources (e.g., news footage, personal letters, government documents) and assess their reliability and perspective, understanding how these sources contribute to our knowledge of history.

3. Empathy and Perspective-Taking: Through examining personal stories and media from the time, students should develop a deeper empathy for the people involved, including soldiers, protesters, and civilians. This goal aims to foster an appreciation for the diverse perspectives surrounding the Vietnam War and encourage students to consider the human element behind historical events.

I am teaching a 6th grade US History class about the impacts of the Vietnam War on popular support for foreign conflict in the United states. Write a FRQ on the Battle of Khe Sanh that asks the students to evaluate the impact of this specific event on American sentiment and support for the conflict. The FRQ prompt should be answerable in 1 paragraph max. Keep the scope of the question limited.`}
        completion={`Evaluate the impact of the Battle of Khe Sanh on American sentiment and support for the Vietnam War. In your response, consider how media coverage of the battle influenced public opinion and contributed to the growth of anti-war sentiment in the United States. Use evidence from primary sources, such as news reports and personal accounts, to support your analysis. Your answer should be concise and no longer than one paragraph.`}
        comment={`It is recommended you apply this to MCQs and any other assessment design
        that should rely on your initial lesson planning.`}
        show={false}
        exampleUrl={
          "https://chat.openai.com/share/019d9db5-e31e-4e7e-a2e8-1a2f6ce87920"
        }
      />

      <p className="mt-6 font-light text-md text-gray-800">
        Instead of explicitly generating a FRQ, we could support its
        construction in different ways. If you are accompanying your FRQ with
        sources for students to use, a potentially viable use case is using the
        LLM to generate fictional sources that align with what the FRQ wants to
        evaluate. Because of chronic hallucination and factual accuracy issues
        with LLMs, the obvious impulse is to use pre-approved sources or one of
        the many tools available to find suitable documents. And if you intend
        on going this route, this approach is a great way to find context for
        the creation of the FRQ itself.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        What if you can’t find the document you have in mind? Or, what if you
        want to evaluate student comprehension in a unique way, asking them to
        synthesize ideas or materials that may not historically exist (i.e. a
        historical figure’s attitude about a new concept X)? This may produce a
        suitable result. Let’s try it out.
      </p>
      <CompletionExample
        title={`5: Generate mock sources`}
        prompt={`FRQ prompt: Evaluate the impact of the Battle of Khe Sanh on American sentiment and support for the Vietnam War. In your response, consider how media coverage of the battle influenced public opinion and contributed to the growth of anti-war sentiment in the United States. Use evidence from primary sources, such as news reports and personal accounts, to support your analysis. Your answer should be concise and no longer than one paragraph.

Given this FRQ, generate 3, 1-2 paragraph sources students can reference in their response.`}
        completion={`Source 1: News Report Analysis from 1968
A pivotal New York Times article, dated February 1968, depicted the Battle of Khe Sanh as a brutal and prolonged engagement, highlighting the siege and the determined resistance of American and South Vietnamese forces against the North Vietnamese. The coverage emphasized the harsh conditions, the high casualty rates, and the uncertainty surrounding the strategic importance of Khe Sanh. This report played a significant role in shaping public perception by presenting the battle not just as a military engagement but as a symbol of the broader struggles and the questionable progress in Vietnam. The vivid descriptions of the conflict and its toll on soldiers served to fuel growing doubts about the justification and management of the war, contributing to an escalation in anti-war sentiment across the United States.

Source 2: Personal Account from a Soldier at Khe Sanh
In a personal account published in 1969, a soldier stationed at Khe Sanh detailed the grueling experience of the siege, describing constant artillery bombardments, the psychological toll of isolation, and the valor of his fellow servicemen in the face of overwhelming odds. This narrative, circulated through magazines and newspapers, provided a firsthand look at the conditions on the ground, contrasting sharply with official reports of progress. The soldier's reflections on the purpose of the sacrifice and the apparent futility of the battle resonated with the American public, stirring discussions about the human cost of the war and reinforcing calls for its end.

Source 3: Television News Broadcast Segment, 1968
A prime-time news segment aired by CBS in March 1968 brought the Battle of Khe Sanh into living rooms across America, utilizing groundbreaking footage of the combat and interviews with soldiers. This powerful visual medium allowed viewers to witness the intensity of the fighting and hear directly from those embroiled in the conflict, making the distant war a tangible and distressing reality. The broadcast raised serious questions about the strategic rationale behind defending Khe Sanh and highlighted the discrepancy between official optimism and the challenging conditions on the ground. The impact of this coverage was profound, contributing to a shift in public opinion as more Americans began to question the war's direction and seek an end to the conflict.`}
        comment={`If there is a document style or layout you have in mind, you could
        provide this as context to help the LLM model the new sources after.`}
        exampleUrl={
          "https://chat.openai.com/share/f6ed0c94-060b-4aa0-8e18-78acd8f85dce"
        }
        show={false}
      />
      <p className="mt-6 font-light text-md text-gray-800">
        Not a bad response. As a general rule of thumb, re-generate multiple
        times (on multiple LLMs) if you don’t immediately get the response you
        want.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        Another way to use LLMs to support FRQ writing is as a feedback tool
        focused on resolving the clarity and accuracy of the question. Let’s ask
        the model to do the following: write sources for a hypothetical FRQ,
        write the FRQ, and then answer the FRQ as a student. With this response,
        we can evaluate the “student’s” response for misunderstanding,
        imprecision, and other traits you would use to evaluate an actual
        student’s response.
      </p>
      {/* <CompletionExample
        title={`7: asdf`}
        prompt={`bad example`}
        completion={`bad output to highlight bad completion`}
        comment={`If you have grading criteria, it could be worth including as additional context for the construction of the FRQ itself.`}
        show={false}
        exampleUrl={""}
      /> */}

      <p className="mt-6 font-light text-md text-gray-800">
        If the model’s response is too vague, it’s possible the FRQ itself is
        too vague. If the response answers the question differently than
        intended, it may be the question was not explicit enough about how to
        answer the question. Again, these are imperfect signals. And because
        LLMs have a tendency to not adhere to certain directions, it may just be
        a listening problem on its part. Nevertheless, if you generate 10
        “student responses” and none of them are close to what you intended, it
        is likely the FRQ needs further fine-tuning. For more techniques to
        generate FRQs, see{" "}
        <a
          href="https://www.k12promptguide.com/usecases/mcqs"
          target="_blank"
          className="hover:cursor-pointer hover:underline text-blue-700"
          rel="noreferrer"
        >
          {"MCQs"}
        </a>
        {"."}
      </p>

      <p className="mt-9 font-normal text-xl scroll-mt-24" id="conclusion">
        Concluding thoughts
      </p>
      <p className="mt-3 font-light text-md text-gray-800">
        FRQ creation is a task LLMs are very capable of doing. There are unique
        differences from other types of assessment generation, with different
        inputs and configurations to get a question that serves the purpose of
        evaluating student understanding at a deeper level.
      </p>
      <FooterNav pageBefore={"MCQs"} pageAfter={"Grading MCQs"} />
    </div>
  );
}
