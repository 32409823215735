import { useNavigate } from "react-router-dom";

export function Breadcrumb({ page, subPage }) {
  const navigate = useNavigate("");
  function navigateTo(page) {
    page = page.replace(" ", "").toLowerCase();
    let path = getPathFromName(page);
    navigate(path);
  }

  return (
    <nav class="flex top-0 ml-[0.5]" aria-label="Breadcrumb">
      <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
        <li class="inline-flex items-center" onClick={() => navigateTo(page)}>
          <a
            href="#"
            class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 duration-300 ease-in-out"
          >
            {page}
          </a>
        </li>
        {subPage === "" ? (
          <div></div>
        ) : (
          <li>
            <div class="flex items-center">
              <svg
                class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <a class="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 duration-300 ease-in-out">
                {subPage}
              </a>
            </div>
          </li>
        )}
      </ol>
    </nav>
  );
}

export function getPathFromName(name) {
  let path = "";

  if (name === "home") {
    path = `/home`;
  }
  if (name === "introduction") {
    path = `/introduction`;
  }
  if (
    name === "artificialintelligence" ||
    name === "languagemodels" ||
    name === "promptengineering"
  ) {
    path = `/introduction/${name}`;
  }
  if (name === "strategies") {
    path = `/strategies`;
  }
  if (
    name === "precision" ||
    name === "labeling" ||
    name === "examples" ||
    // name === "decomposition" ||
    name === "contextualization"
  ) {
    path = `/strategies/${name}`;
  }

  if (name === "usecases") {
    path = `/usecases`;
  }
  if (
    name === "freeresponse" ||
    name === "mcqs" ||
    name === "projectassignments" ||
    name === "learningobjectives" ||
    name === "lessonplans" ||
    name === "emails" ||
    name === "mcqgrading" ||
    name === "freeresponsegrading" ||
    name === "roleplaying"
  ) {
    path = `/usecases/${name}`;
  }

  if (name === "commonmisconceptions") {
    path = `/commonmisconceptions`;
  }

  if (name === "tools") {
    path = `/tools`;
  }

  if (name === "additionalreadings") {
    path = `/additionalreadings`;
  }

  return path;
}
