import React from "react";
import { Breadcrumb } from "../../Components/ContentPage/ContentRendering/Breadcrumb";
import FooterNav from "../../Components/ContentPage/Menus/FooterNav";

export default function CommonMisconceptions({}) {
  // TODO: create map
  let commonQuestions = {
    "What is artificial intelligence?": "",
    "": "",
  };

  return (
    <div>
      <Breadcrumb page={"Common Questions"} subPage={""} />
      <h1 className="font-semibold text-3xl mt-2">Common Questions</h1>
      <p className="mt-3 font-light text-md text-gray-800">
        Questions often abound with the introduction and use of any new or
        updated technology. This certainly holds true for AI in K-12 education.
        Below is a list of common questions about artificial intelligence that
        we have come across or have been asked as we have worked with and
        collaborated with educators. We will regularly add to these questions.{" "}
      </p>
      <div className="mt-6 bg-gray-50 p-4 rounded-md">
        <p className="font-semibold text-lg text-gray-800">
          What is artificial intelligence?
        </p>
        <p className="font-light text-md text-gray-800">
          According to ChatGPT, “AI is a broad field of computer science that
          aims to create machines or systems that can perform tasks that
          typically require human intelligence. These tasks include reasoning,
          problem-solving, perception, learning, language understanding, and
          more. AI encompasses a wide range of techniques and approaches,
          including rule-based systems, machine learning, neural networks, and
          natural language processing."
        </p>
      </div>
      <div className="mt-6 bg-gray-50 p-4 rounded-md">
        <p className="font-semibold text-lg text-gray-800">
          How long has AI been around?
        </p>
        <p className="font-light text-md text-gray-800">
          Artificial intelligence is not a new concept. The foundation of AI
          began in the 1940s and 1950s. It may seem, however, that it is
          relatively new based on the amount of media attention it is currently
          receiving and based on the proliferation of generative AI tools.
          Artificial intelligence growth has expanded exponentially starting in
          the 2010s and continuing into the 2020s when large language models
          like GPT became widely available.
        </p>
      </div>
      <div className="mt-6 bg-gray-50 p-4 rounded-md">
        <p className="font-semibold text-lg text-gray-800">Is AI sentient?</p>
        <p className="font-light text-md text-gray-800">
          Although it may seem like artificial intelligence is all-knowing,
          self-aware, and has consciousness, it is not sentient. Artificial
          intelligence can perform complex tasks and exhibit remarkable
          capabilities in various contexts; what it lacks, however, is
          consciousness and the necessary self-awareness that would allow it to
          have feelings and the ability to think on its own
        </p>
      </div>
      <div className="mt-6 bg-gray-50 p-4 rounded-md">
        <p className="font-semibold text-lg text-gray-800">
          Are AI and LLMs the same thing?
        </p>
        <p className="font-light text-md text-gray-800">
          No. They are, however, related concepts. Artificial intelligence is an
          extensive field within computer science that aims to get computers to
          perform tasks that typically require human intelligence (see previous
          question about what is artificial intelligence). Large language models
          are a subset of AI; they are a specific type of AI model designed for
          natural language understanding and generation.
        </p>
      </div>
      <div className="mt-6 bg-gray-50 p-3 rounded-md">
        <p className="font-semibold text-lg text-gray-800">
          Is ChatGPT artificial intelligence?
        </p>
        <p className="font-light text-md text-gray-800">
          Yes. ChatGPT is an artificial intelligence language model developed by
          OpenAI.
        </p>
      </div>
      <div className="mt-6 bg-gray-50 p-4 rounded-md">
        <p className="font-semibold text-lg text-gray-800">
          What are some AI tools that I might be familiar with or have possibly
          used?
        </p>
        <p className="font-light text-md text-gray-800">
          Artificial intelligence is used in many different tools that you most
          likely use on a daily basis. ChatGPT is one of the most common tools.
          Artificial intelligence is also used in writing tools (e.g., Google
          Docs, Grammarly, iMessages) to suggest word spellings and sentence
          completion. Grammarly examines the context of your writing to help
          improve the quality of your writing.
        </p>
      </div>
      <div className="mt-6 bg-gray-50 p-4 rounded-md">
        <p className="font-semibold text-lg text-gray-800">
          What are moral and ethical considerations with using AI for teaching
          and learning?
        </p>
        <p className="font-light text-md text-gray-800">
          There are numerous moral and ethical considerations that should be
          considered before and while using AI for teaching and learning. The
          following are a few areas to consider: privacy, bias, access,
          transparency and accountability, informed consent, and job impact. In
          future iterations of this web resource, these moral and ethical
          considerations will be explored and expanded on. For now, however, we
          highlight informed consent. It is highly important to ensure that all
          stakeholders are aware if AI is being used in the classroom and how it
          is being used. Stakeholders should be able to consent to using it or
          not (although, this gets into a tricky area of what to do if
          stakeholders opt out of using it). It is important to note that there
          is often a minimum age requirement for creating an account to use many
          AI tools (e.g., ChatGPT and Google Bard users must be at least 13).
        </p>
      </div>
      <div className="mt-6 bg-gray-50 p-3 rounded-md">
        <p className="font-semibold text-lg text-gray-800">
          What happens when I submit an input to ChatGPT?
        </p>
        <p className="font-light text-md text-gray-800">
          The large language model receives your input, completes the necessary
          computations, and returns the output. The LLM itself does not store
          any information about your input or output, as LLMs are stateless. For
          example, if you plugged numbers into a basic equation, you receive an
          output, but the equation does not “remember” anything about your
          input.
        </p>
      </div>
      <div className="mt-6 bg-gray-50 p-4 rounded-md">
        <p className="font-semibold text-lg text-gray-800">
          How does ChatGPT (or other LLMs) use my data?
        </p>
        <p className="font-light text-md text-gray-800">
          How ChatGPT (and similar products) use your data is company-specific (
          <a
            href="https://openai.com/policies/terms-of-use"
            target="_blank"
            className="hover:cursor-pointer hover:underline text-blue-700"
            rel="noreferrer"
          >
            {"OpenAI's Terms of Use"}
          </a>{" "}
          are refreshingly straightforward). Most commonly, companies store
          information about your account (account info, chat conversations, etc)
          for usability purposes. How a company uses your data beyond basic
          storage (selling information, training new models) is again,
          company-specific. It is worth noting that OpenAI publicly states they
          do not use ChatGPT conversations for any training. For a comprehensive
          review of different LLM products and their capabilities, you can visit
          the Tools section.
        </p>
      </div>
      <div className="mt-6 bg-gray-50 p-3 rounded-md">
        <p className="font-semibold text-lg text-gray-800">
          Will AI replace educators?
        </p>
        <p className="font-light text-md text-gray-800">
          We know this is something that brings consternation to some. This is
          understandable. Although AI can do some tasks–arguably more
          efficiently than a human–AI is nowhere near the point where it will
          replace educators. We do not yet have to fear AI overlords.
        </p>
      </div>
      <div className="mt-6 bg-gray-50 p-4 rounded-md">
        <p className="font-semibold text-lg text-gray-800">
          Will AI help improve teaching and learning?
        </p>
        <p className="font-light text-md text-gray-800">
          Only time will tell as to whether AI will truly improve teaching and
          learning. There are initial indications that AI is being used in
          effective ways. It is important to note that these indications mostly
          come from anecdotal evidence from firsthand experience. This is not
          meant to discredit these firsthand experiences. Nor are we
          discrediting the research that is being conducted and shared on the
          use of AI in instructional contexts. This body of research is growing
          and will continue to grow. This is important research that will guide
          how and when AI should be used in teaching and learning.
        </p>
      </div>
      <div className="mt-6 bg-gray-50 p-4 rounded-md">
        <p className="font-semibold text-lg text-gray-800">
          What considerations should I have for using AI with K-12 students?
        </p>
        <p className="font-light text-md text-gray-800">
          As with any technology that is used with students, you should identify
          why you are having your students use it. What is the instructional
          outcome you hope to gain by having your students engage with AI?
          Answering this question will help you to be purposeful in your use of
          AI. Additionally, you should consider whether your students are
          allowed to access AI. There often is a minimum age requirement for
          creating an account to use many AI tools. ChatGPT and Google Bard
          users must be at least 13 years old.
        </p>
      </div>
      <FooterNav pageBefore={"Role Playing"} pageAfter={"Tools"} />
    </div>
  );
}
