import React from "react";
import { Breadcrumb } from "../../Components/ContentPage/ContentRendering/Breadcrumb";
import FooterNav from "../../Components/ContentPage/Menus/FooterNav";

export default function UseCases({}) {
  return (
    <div>
      <Breadcrumb page={"Use Cases"} subPage={""} />
      <h1 className="font-semibold text-3xl mt-2">Use Cases</h1>
      {/* <p className="mt-3 font-light text-md text-gray-800">
        There is an overwhelming amount of information online detailing the
        various use cases of LLMs for K-12 stakeholders. Among the most popular
        are: lesson planning, instructional design, lecture, handout, and
        assignment creation, grading, student, parent, and staff communications,
        and professional development. Much of this information exists in the
        form of prompt templates, handouts, and other resources.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        Although these resources have their moments, they do not go nearly far
        enough to prepare K-12 teachers and administrators to effectively use
        LLMs in schools. Because of the non-deterministic nature of LLMs,
        prompting is inherently experimental. Thus, you must have the skills to
        construct novel prompts and methodologically debug issues.
      </p> */}
      <p className="mt-6 font-light text-md text-gray-800">
        The purpose of this section is two-fold. First, you will work through
        different examples of real-world use cases to create state-of-the-art
        prompt templates you can refer back to. Second (and more importantly),
        you will build an intuition for the process of taking a unique task and
        iteratively developing a prompt that produces high-quality outputs.
      </p>{" "}
      <p className="mt-6 font-light text-md text-gray-800">
        By the nature of LLMs’ general-reasoning capabilities and continual
        improvements, there are really an infinite number of ways you can use
        LLMs like ChatGPT in the classroom and school more broadly. This section
        aims to give you practice that will allow you to navigate these infinite
        number of use cases at your discretion.
      </p>
      <FooterNav pageBefore={"Contextualization"} pageAfter={"MCQs"} />
    </div>
  );
}
