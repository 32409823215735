import React, { useEffect, useState } from "react";
import MobileContentMenu from "./MobileContentMenu";
import { useNavigate } from "react-router-dom";

export default function NewNavBar({}) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate("");

  function navigateTo(page) {
    navigate(page);
  }

  function toggleMenu() {
    setMenuOpen(!menuOpen);
  }

  function selectedCallback() {
    setMenuOpen(false);
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <div className="w-screen bg-gray-50 border-gray-200 border-solid border-b left-0 top-0 fixed z-50 px-10">
        <div className="flex flex-row justify-between items-center p-4">
          <div
            onClick={() => navigateTo("/home")}
            className="flex flex-row justify-start items-center hover:text-blue-500 duration-300 ease-in-out hover:cursor-pointer"
          >
            <img
              className="w-10 h-10"
              src="https://i.imgur.com/3kzmbhY.png"
              alt="K-12 Prompt Engineering Guide Logo"
            ></img>
            <h1 className="ml-6 font-semibold max-[650px]:text-md text-lg leading-5">
              K-12 Prompt Engineering Guide
            </h1>
          </div>
          {windowWidth > 800 ? (
            <div className="flex flex-row justify-center items-center text-sm text-slate-800">
              <h1
                className="hover:cursor-pointer hover:text-blue-500 duration-300 ease-in-out"
                onClick={() => navigateTo("/")}
              >
                Home
              </h1>
              <h1
                className="ml-6 mr-6 hover:cursor-pointer hover:text-blue-500 duration-300 ease-in-out"
                onClick={() => navigateTo("/contact")}
              >
                Contact
              </h1>
            </div>
          ) : (
            <div className="flex flex-row justify-center items-center mr-6 hover:cursor-pointer">
              <span className="material-symbols-outlined" onClick={toggleMenu}>
                Menu
              </span>
            </div>
          )}
        </div>
      </div>

      {/* Conditional rendering of the overlay menu */}
      {menuOpen && windowWidth < 800 && (
        <div className="fixed top-[72px] w-screen h-screen bg-black z-50">
          {/* You can customize the menu content here */}
          <div className="bg-white">
            <MobileContentMenu selectedCallback={selectedCallback} />
          </div>
        </div>
      )}
    </div>
  );
}
