import React from "react";
import { Breadcrumb } from "../../Components/ContentPage/ContentRendering/Breadcrumb";
import FooterNav from "../../Components/ContentPage/Menus/FooterNav";
import CompletionExample from "../../Components/ContentPage/ContentRendering/CompletionExample";

export default function MCQs({}) {
  return (
    <div className="">
      <Breadcrumb page={"Use Cases"} subPage={"MCQs"} />
      <h1 className="font-semibold text-3xl mt-2">
        Multiple-choice questions (MCQs)
      </h1>

      <p className="mt-3 font-normal text-xl scroll-mt-24" id="problems">
        The current problem(s) with MCQs
      </p>
      <p className="mt-3 font-light text-md text-gray-800">
        Multiple-choice questions (MCQs) are a prototypical assessment type for
        K-12 educators to quickly and scalably evaluate student comprehension of
        certain types of content. This assessment type can act as a benchmark
        for determining student understanding. MCQ assessments can be
        accomplished through software that can digitally administer and grade
        student work.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        Despite the ease in which MCQ assessment can be administered and graded,
        the core competency of writing clear, content-relevant, unexploitable
        MCQs can be a difficult one to master. This process often involves
        multiple laborious steps: deciding which lesson or unit content to focus
        on for the assessment, writing clear and unambiguous questions about the
        content, and writing answer options that are not misinterpretable and
        not revealing of answer information.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        It is because of the effort it takes to get effective at the ‘content to
        MCQ’ workflow that some opt to use previous years’ examinations, test
        banks, or outright avoid preparing this type of assessment. However,
        when MCQs are well written, they can be a great tool to help determine
        student understanding of content.
      </p>
      <p className="mt-9 font-normal text-xl scroll-mt-24" id="llmshelp">
        How LLMs can help you write MCQs
      </p>
      <p className="mt-3 font-light text-md text-gray-800">
        Fret not! If we haven’t already implied dozens of times through this
        guide, LLMs are a powerful tool that can facilitate and even outright
        help replace many rote, ‘busy-work’ tasks teachers are faced with. LLMs
        are highly effective at helping you draft and prepare high-quality MCQs
        for student assessments. So, if LLMs are currently used by your students
        to circumvent studying or answering questions legitimately, it’s time to
        “use the stones to destroy the stones” by leveraging AI to streamline
        your MCQ writing process. Let’s explore how to create MCQs using LLMs.
      </p>
      <p className="mt-9 font-normal text-xl scroll-mt-24" id="experiment">
        Experiment with prompts for MCQs
      </p>
      <p className="mt-3 font-light text-md text-gray-800">
        Let’s start by creating a standard multiple-choice question on surface
        tension.
      </p>
      <CompletionExample
        title={`1: Basic multiple-choice question`}
        prompt={`Write a 4-selection MCQ about the definition of surface tension for my 10th grade biology class.`}
        completion={`What is Surface Tension?

Surface tension is a property of the surface of a liquid that allows it to resist an external force, due to the cohesive nature of its molecules. Which of the following best describes surface tension?

A) The force that causes a liquid to expand and take up more space.
B) The attraction between molecules of different substances that pulls the liquid molecules to the container's sides.
C) The attraction between the molecules in a liquid that causes the surface layer to act as a stretched elastic membrane.
D) The tendency of a liquid to evaporate quickly and turn into a gas`}
        comment={``}
        show={false}
        exampleUrl={
          "https://chat.openai.com/share/ad78d2b7-29c5-42c4-8883-ed7483b097d5"
        }
      />

      <p className="mt-6 font-light text-md text-gray-800">
        Without any additional information, the LLM writes a high-quality MCQ
        with 4 answer options and an answer key. Immediately, you can navigate
        the LLM to change multiple basic properties about this question, such
        as: the # of answer options, the lesson content the question is testing,
        or length of the question/answer.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        We can get fairly specific when customizing the MCQ the model outputs.
        There are different fields of thought when it comes to how answer
        options should be written. As an example, we can direct the model to
        write answer options that are highly similar to one another, with
        nuanced words or phrases differentiating the correct from incorrect
        answers.
      </p>
      <CompletionExample
        title={`2: Similar answer options`}
        prompt={`Write a MCQ with 4 answer options about the definition of surface tension for my 10th grade biology class.

Make the answer options similar to one another, using nuance to subtly differentiate correct from incorrect answers.`}
        completion={`Question: What is the definition of surface tension?

A) The resistance of a liquid's surface to an external force, due to the cohesive forces between liquid molecules.

B) The attraction between the molecules of different substances that causes one liquid to spread across the surface of another.

C) The force acting at the surface of a liquid that causes it to behave as though its surface were covered with a stretched elastic membrane.

D) The pressure difference between the inside and outside of a liquid droplet, resulting from the cohesive forces between liquid molecules.

Correct Answer: C) The force acting at the surface of a liquid that causes it to behave as though its surface were covered with a stretched elastic membrane.`}
        comment={`This also applies for different types of MCQ questions. For example, an
        MCQ that asks students to select the correct definition of surface
        tension versus an MCQ that describes surface tension and asks the
        student to select the correct term for the description.`}
        show={false}
        exampleUrl={
          "https://chat.openai.com/share/252e5f7e-e62d-4872-8cb2-ac4d464f9567"
        }
      />

      <p className="mt-6 font-light text-md text-gray-800">
        There are many flavors to experiment with. Because of the trickiness of
        MCQs in general, it could be helpful to ask the model to generate
        multiple correct options, or a backup ‘correct’ answer students could
        receive partial credit on. With all of this configured, you could
        further direct the model to generate a specific number of MCQs. Keep in
        mind it’s always best practice to decompose a larger job (writing an
        entire quiz) into subtasks (write each question). But maybe you are
        interested in ‘sampling’ different MCQs written for the same content.
      </p>
      <CompletionExample
        title={`3: Create 5 versions of the above`}
        prompt={`Write a MCQ with 4 answer options about the definition of surface tension for my 10th grade biology class. Make the answer options similar to one another, using nuance to subtly differentiate correct from incorrect answers.

Write 5 versions of the question.`}
        completion={`Question 1: What is surface tension in the context of biological systems?

A) The force that acts on the surface of a liquid, making it act like a stretched elastic membrane.
B) The attraction between molecules in a liquid that provides a slightly rigid layer at the liquid's surface.
C) The resistance of a liquid to increase its surface area, due to cohesive forces between liquid molecules.
D) The pressure exerted by liquids as a result of gravity, causing them to form a flat or curved surface.

Correct Answer: C) The resistance of a liquid to increase its surface area, due to cohesive forces between liquid molecules.


Question 2: How does surface tension affect biological processes?

A) It is the phenomenon that allows certain objects, even if denser than the liquid, to float on a liquid surface.
B) It is the tendency of liquid surfaces to shrink into the minimum surface area possible, impacting the shape of cell membranes.
C) It is the capacity of a liquid to flow in narrow spaces without the assistance of external forces like gravity.
D) It is the force exerted by the surface of a liquid that causes it to behave as though it is covered by a thin membrane.

Correct Answer: A) It is the phenomenon that allows certain objects, even if denser than the liquid, to float on a liquid surface.


Question 3: Which statement best describes the cause of surface tension in water molecules within biological systems?

A) The cohesive force between water molecules due to hydrogen bonding that pulls the molecules closer together at the surface.
B) The adhesive force between different types of molecules that allows water to climb upward against gravity.
C) The kinetic energy of water molecules that increases the space between them, reducing the liquid's density.
D) The gravitational pull on water molecules that aligns them in a structured pattern, creating a firm surface.

Correct Answer: A) The cohesive force between water molecules due to hydrogen bonding that pulls the molecules closer together at the surface.


Question 4: In the context of biology, how can surface tension be defined with respect to water's behavior on a leaf?

A) The capability of water droplets to spread out evenly across the leaf's surface, maximizing contact area.
B) The tendency of water droplets to form beads on a leaf, due to the cohesive forces among water molecules being stronger than adhesive forces with the leaf.
C) The ability of water to penetrate the leaf's surface, facilitated by the adhesive forces between water and the leaf.
D) The force that causes water to evaporate from the leaf's surface, contributing to the transpiration process.

Correct Answer: B) The tendency of water droplets to form beads on a leaf, due to the cohesive forces among water molecules being stronger than adhesive forces with the leaf.


Question 5: What role does surface tension play in the capillary action observed in plants?

A) It is the driving force that causes water to move up against gravity through the plant's xylem.
B) It is a repellent force that prevents water from ascending the xylem, requiring energy to overcome.
C) It acts by decreasing the diameter of water columns in the xylem, thus reducing the efficiency of water transport.
D) It enhances the adhesive forces between water and xylem walls, but does not contribute directly to water movement.

Correct Answer: A) It is the driving force that causes water to move up against gravity through the plant's xylem.`}
        comment={`Sample your favorite and workshop.`}
        show={false}
        exampleUrl={
          "https://chat.openai.com/share/9eb63fa7-f8ab-4e71-a06a-359258895cbe"
        }
      />
      <p className="mt-6 font-light text-md text-gray-800">
        If you’ve read the articles from the{" "}
        <a
          href="https://www.k12promptguide.com/strategies"
          target="_blank"
          className="hover:cursor-pointer hover:underline text-blue-700"
          rel="noreferrer"
        >
          {"Strategies"}
        </a>{" "}
        section, you likely have some ideas for improving these prompts further.
        A particularly relevant strategy to the MCQ writing task is{" "}
        <a
          href="https://www.k12promptguide.com/strategies/contextualization"
          target="_blank"
          className="hover:cursor-pointer hover:underline text-blue-700"
          rel="noreferrer"
        >
          {"Contextualization"}
        </a>
        {""}. As in, including information from lecture notes, presentation
        slides, and textbook readings as context to ground the LLM in generating
        highly-specific content questions.
      </p>
      <CompletionExample
        title={`4: Use excerpt from textbook/lesson`}
        prompt={`Textbook excerpt: Have you ever filled up a glass of water to the very top and then slowly added a few more drops? Before it overflows, the water forms a dome-like shape above the rim of the glass. Water can stay above the glass because of the property of cohesion. In cohesion, water molecules are attracted to each other because of hydrogen bonding, keeping the molecules together at the liquid-air, gas, interface. Cohesion gives rise to surface tension, the capacity of a substance to withstand rupture when placed under tension or stress. When you drop a small scrap of paper onto a droplet of water, the paper floats on top of the water droplet. The paper floats even though the object is denser, heavier than the water. This occurs because of the surface tension that is created by the water molecules. Cohesion and surface tension keep the water molecules intact and the item floating on the top of the water's surface. It is even possible to “float” a steel needle on top of a glass of water if you place it gently without breaking the surface tension (Figure 2.28).

Using the above excerpt, write a MCQ with 4 answer options about the definition of surface tension for my 10th grade biology class.`}
        completion={`Here's a multiple-choice question (MCQ) based on the provided textbook excerpt suitable for your 10th grade biology class:

Question: What is the definition of surface tension?

A) The ability of a liquid to flow in narrow spaces without the assistance of external forces.

B) The temperature at which a liquid boils and turns to vapor.

C) The capacity of a substance to withstand rupture when placed under tension or stress due to the cohesive forces between liquid molecules.

D) The process by which liquid water turns into ice at 0°C.

Correct Answer: C) The capacity of a substance to withstand rupture when placed under tension or stress due to the cohesive forces between liquid molecules.`}
        comment={`Not only does this help ground the questions in content students are
        expected to have practiced, but it prevents MCQs being written that
        stray away from explicitly discussed content.`}
        exampleUrl={
          "https://chat.openai.com/share/a7357ce4-5d08-4e38-a676-23b36c556efe"
        }
        show={false}
      />

      <p className="mt-6 font-light text-md text-gray-800">
        An iteration of this additional context is providing unit, chapter, or
        class-level guidance on what students should be learning, the purpose of
        MCQ assessments, and other 10,000 foot information that the model can
        use to write even better MCQs. For example, if the previous chapter’s
        content integrates closely into a current chapter, you could include
        chapter content as additional context. Maybe, you even provide the
        previous chapter’s MCQ assessment and direct the model to write
        questions that build on the knowledge tested during the previous quiz!
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        These are great MCQs, but this format can be a bit generic and
        repetitive. Fortunately, with only a little additional work, we can
        generate different variations of these same MCQs, such as ‘Select all
        that apply’ or ‘All of the following except’ to diversify the
        assessment’s content.
      </p>
      <CompletionExample
        title={`5: "All of the following except"`}
        prompt={`Write an 'all of the following except' multiple-choice question with 4 answer options about the definition of surface tension for my 10th grade biology class.`}
        completion={`Sure, here's a multiple-choice question designed for your 10th grade biology class:

Question: Which of the following statements about surface tension is NOT true?

A) Surface tension is the elastic tendency of liquids which makes them acquire the least surface area possible.

B) Surface tension is caused by the cohesive forces between liquid molecules at the surface of the liquid.

C) Surface tension allows certain objects, even if denser than water, to float on its surface if they do not break the surface.

D) Surface tension increases as the temperature of the liquid increases, because the kinetic energy of the molecules decreases.

Correct Answer: D) Surface tension increases as the temperature of the liquid increases, because the kinetic energy of the molecules decreases.

Explanation: This statement is not true because surface tension actually decreases as the temperature of the liquid increases. The increase in temperature increases the kinetic energy of the molecules, which in turn decreases the cohesive forces between the molecules at the surface, leading to a decrease in surface tension.`}
        comment={`Because the MCQ structure is similar but it is testing something
        conceptually distinct, it is worth running these prompts multiple times
        to see if there are quality or reliability issues generating this less
        common type of MCQ. This is a good practice in general.`}
        show={false}
        exampleUrl={
          "https://chat.openai.com/share/183f5742-8bb8-4703-b243-5bc6f068154b"
        }
      />

      <p className="mt-6 font-light text-md text-gray-800">
        Why stop there? In researching for this guide, we came across multiple
        experimental versions of MCQs, including variations on the number and
        type of answers, the “action” the student is performing (e.g., choose
        one, choose multiple, choose the incorrect statement), how the question
        draws on content, and others. It is also worth considering how to create
        MCQ-esque questions that would fit alongside MCQs in an assessment:
        fill-in-the-blank, ‘X is to Y’, and term-definition matching are some of
        the few.
      </p>
      <CompletionExample
        title={`6: Fill-in-the-blank`}
        prompt={`Write a 'fill in the blank' multiple-choice question with 4 answer options about the definition of surface tension for my 10th grade biology class.`}
        completion={`Question:

Surface tension is a property of the surface of a liquid that allows it to resist an external force, due to the cohesive nature of its molecules. This property is the reason why certain insects can walk on water without sinking. Surface tension is primarily caused by the __________ between molecules at the surface of a liquid.

Options:

A) Gravitational attraction

B) Electrical repulsion

C) Magnetic interaction

D) Attractive forces

Correct Answer: D) Attractive forces`}
        comment={``}
        show={false}
        exampleUrl={
          "https://chat.openai.com/share/cf681d83-7035-49cc-b871-90931202bf89"
        }
      />
      <p className="mt-6 font-light text-md text-gray-800">
        Using the above techniques, you’ll be able to generate high-quality
        drafts of MCQs. Thinking of the LLM as helping you get down a first
        outline, you can make the necessary adjustments to get the questions
        ready to be included on an assessment. However, this doesn’t mean you
        are immune from the common shortcomings of writing MCQs. What if a
        question is written unclearly? What if a question has answer options
        that conflict or include content that has not been covered?
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        As we will discuss later in{" "}
        <a
          href="https://www.k12promptguide.com/usecases/gradingmcqs"
          target="_blank"
          className="hover:cursor-pointer hover:underline text-blue-700"
          rel="noreferrer"
        >
          {"Grading MCQs"}
        </a>
        , you can further leverage LLMs to provide clarity for your MCQs by
        having it “take” the quiz you’ve prepared and evaluate the results.
      </p>
      <CompletionExample
        title={`7: 5 question quiz`}
        prompt={`Write a 5 question quiz on surface tension for my 10th grade biology class. Each question should have 5 answer options.

Include: 2 MCQs, 1 fill-in-the-blank, 1 'select-all-that-apply', and a 'all of the following except'.`}
        completion={`1. What is surface tension? (MCQ)
A) The tension in muscles at the surface of the body
B) The force exerted by the surface of a liquid that causes it to behave as though its surface is covered with a stretched elastic membrane
C) The pressure inside a bubble due to the presence of a liquid
D) The resistance of a solid to external forces
E) The attraction between molecules in a gas

Correct Answer: B

2. Which property of water is primarily responsible for its high surface tension? (MCQ)
A) Thermal conductivity
B) Viscosity
C) Hydrogen bonding
D) Covalent bonding
E) Ionic bonding

Correct Answer: C

3. Surface tension allows certain insects to walk on water. This phenomenon is due to water's _____________. (Fill-in-the-blank)
Answer: cohesion

4. Select all that apply: Which of the following factors can affect the surface tension of a liquid? (Select-all-that-apply)
A) Temperature
B) Presence of surfactants
C) Color of the liquid
D) Type of solutes dissolved
E) Atmospheric pressure

Correct Answers: A, B, D, E

5. All of the following are examples of surface tension in everyday life EXCEPT: (All of the following except)
A) A needle floating on the surface of water
B) Raindrops forming spherical shapes
C) Oil spreading on the surface of water
D) A balloon popping when pricked with a sharp object
E) Soap bubbles forming from soapy water

Correct Answer: D`}
        comment={`This is without any context provided to the model. What if
        students are taking an open-note quiz?`}
        show={false}
        exampleUrl={`https://chat.openai.com/share/6f5df2b2-405d-4540-9df2-593ec2d6147b`}
      />
      <p className="mt-6 font-light text-md text-gray-800">
        Run this ~10 times on different LLMs and see how accurate the responses
        are. Considering the increasingly intelligent nature of LLMs, and their
        substantial coverage of K-12 knowledge that you would anticipate a
        student to know, if one or more questions are continuously answered
        incorrectly, there is likely a problem with the MCQ (or the answer from
        the key!)
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        It isn’t immediately clear that a model’s incorrect answer is an
        indictment of the MCQs you prepared. For particularly complicated or
        niche content areas, it is possible the LLM simply does not know the
        answer and is hallucinating! Regardless of the cause, another test would
        include asking the LLM to complete the quiz, given snippets of content
        from the lesson(s) you expect students to prepare, instead of taking it
        cold turkey.
      </p>
      {/* <CompletionExample
        title={`8: asdf`}
        prompt={`with context`}
        completion={`answers`}
        comment={``}
        show={false}
      />
      <p className="italic text-center font-light text-md text-gray-800">
        If this is a one-page cheat sheet or open-note quiz, it is likely
        advantageous to use this context both in generating the MCQs and
        evaluating their clarity.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        Now that we have techniques for creating effective MCQs, let’s consider
        a core capability of LLMs that is deeply valuable to help prepare
        student assessments: rewriting and rewording. As we referred to at the
        beginning of this article, a chronic problem in education is cheating. A
        pernicious form involves students discovering questions likely to be on
        an assessment and studying them or their content areas. If students can
        come to expect questions written in a certain way or derived from a
        certain source, assessments as an instructional and evaluation tool will
        become obsolete.
      </p> */}
      <p className="mt-6 font-light text-md text-gray-800">
        Are students finding and studying questions from previous years’
        assessments? If so, you can have the LLM rewrite each question,
        preserving the content but changing the answer options.
      </p>
      <CompletionExample
        title={`9: Rewrite an old question`}
        prompt={`1. What is surface tension?
A) The tension in muscles at the surface of the body
B) The force exerted by the surface of a liquid that causes it to behave as though its surface is covered with a stretched elastic membrane
C) The pressure inside a bubble due to the presence of a liquid
D) The resistance of a solid to external forces
E) The attraction between molecules in a gas

Correct Answer: B

I am a 10th grade biology teacher. I used this MCQ on a previous quiz - rewrite for my students this year.`}
        completion={`Here's a rewritten version of your multiple-choice question on surface tension for this year's quiz:

Which of the following best describes the phenomenon of surface tension?

A) The discomfort felt in the outer muscles due to prolonged physical activity
B) The natural tendency of a liquid's surface to shrink into the minimum surface area possible, acting as if it is covered by an invisible elastic layer
C) The force that acts on the surface of a bubble, keeping it intact in a liquid
D) The ability of a solid material to withstand bending or breaking when under force
E) The mutual attraction observed between the molecules of a gas

Correct Answer: B`}
        comment={`Go one step further: have it rewrite the question based on the new
        answer options.`}
        show={false}
        exampleUrl={
          "https://chat.openai.com/share/1e26836c-7580-4a6f-a38d-355991d5b935"
        }
      />

      <p className="mt-6 font-light text-md text-gray-800">
        This could apply to questions found through online pay-for-access
        platforms, student chats/ forums, and other places where your questions
        could be exposed. This form of cheating is particularly a problem for
        publishers with test banks that get leaked.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        Are students able to cheat in-class (or remotely if taking a quiz
        online) by collaborating with other students? With your own MCQs
        written, use an LLM to generate multiple copies of the exam. What was
        once an extremely labor intensive exercise is now rather
        straightforward.
      </p>
      {/* <CompletionExample
        title={`10: asdf`}
        prompt={`question + create 3 versions`}
        completion={`3 versions of question`}
        comment={``}
        show={false}
      />
      <p className="italic text-center font-light text-md text-gray-800">
        If you want to go a step further, you could specify the different
        content areas for the new questions to pull from or specific concepts
        not focused on in other MCQs.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        If you are feeling particularly ambitious, consider generating unique
        copies for each student! Or, refer back to the samples you previously
        generated for each MCQ and choose your favorites. This MCQ rewrite could
        also occur by converting one type of MCQ into another. Given all the
        options above, let’s instruct an LLM to take a basic MCQ and convert it
        into a ‘Select all that applies’.
      </p>
      <CompletionExample
        title={`11: asdf`}
        prompt={`old questions + questions to convert`}
        completion={`converted question`}
        comment={``}
        show={false}
      /> */}
      <p className="mt-9 font-normal text-xl scroll-mt-24" id="conclusion">
        Concluding thoughts
      </p>
      <p className="mt-3 font-light text-md text-gray-800">
        The primary goal of this article was to describe several different ways
        to use LLMs to design, test, and rewrite MCQs. Although we placed
        attention on students cheating when answering MCQs, it was not our
        intent to over emphasize this. We know that most students do not cheat.
        Our intent with this article was to describe how–when LLMs are asked to
        complete highly specific, specialized tasks (such as writing an
        MCQ)–that they demonstrate how powerful of a tool they can be. With the
        techniques we discussed in this article, we are confident you can
        improve the effectiveness of your assessment workflows, which will allow
        you to better evaluate your students' understanding of content and free
        you to focus on helping them learn important content.
      </p>

      <FooterNav pageBefore={"Use Cases"} pageAfter={"FRQs"} />
    </div>
  );
}
