import React, { useState, useEffect } from "react";

export default function CompletionExample({
  title,
  prompt,
  completion,
  comment,
  show,
  exampleUrl,
}) {
  const [isSet, setIsSet] = useState(show);

  function secondCallback(isSet, titleReturned) {
    console.log(`isSet: ${isSet}\ntitleReturned: ${titleReturned}`);
    if (titleReturned[0] === title[0]) {
      setIsSet(isSet);
    }
  }

  return (
    <div
      className="my-10 bg-[#eeeeee2b] rounded-lg px-4 py-1 border border-gray-100 shadow-sm border-solid scroll-mt-24"
      id={title[0]}
    >
      <div className="mt-4">
        <PromptExample
          number={title}
          prompt={prompt}
          exampleUrl={exampleUrl}
          callback={secondCallback}
        />
      </div>
      <div className="mt-2">
        {isSet ? (
          <div>
            <Typewriter text={completion} />
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <p className="mt-6 mb-6 text-gray-800 italic font-light text-sm text-center">{comment}</p>
    </div>
  );
}

export function PromptExample({ prompt, number, exampleUrl, callback }) {
  const [copied, setCopied] = useState(false);
  const [generated, setGenerated] = useState(false);

  function copyToClipboard() {
    navigator.clipboard.writeText(prompt);
    setCopied(true);
  }

  function openChatGPTExample() {
    const newWindow = window.open(exampleUrl, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  }

  function callCallback(direction) {
    console.log(`Direction: ${direction}\nGenerated: ${generated}`);

    if (direction === "restart") {
      if (generated) {
        setGenerated(false);
        callback(false, number);
      }
    }

    if (direction === "complete") {
      if (!generated) {
        console.log("should generate");
        setGenerated(true);
        callback(true, number);
      }
    }
  }

  return (
    <div>
      <h1 className="font-semibold text-lg text-slate-800 ">
        Prompt #{number}
      </h1>
      <div className="mt-4 bg-gray-100 p-4 rounded-lg shadow-sm">
        <h1 className="text-sm text-slate-800 whitespace-pre-wrap font-mono font-extralight">
          {prompt}
        </h1>
        <div className="mt-4 flex flex-row justify-end ">
          {/* <span
            onClick={() => callCallback("restart")}
            class="material-symbols-outlined mr-2 hover:cursor-pointer text-slate-800 hover:text-slate-950 duration-200 ease-in-out"
          >
            restart_alt
          </span> */}
          <div className="mr-3 hover:cursor-pointer">
            <img
              onClick={openChatGPTExample}
              className="w-5 mt-0.5 h-auto"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/ChatGPT_logo.svg/120px-ChatGPT_logo.svg.png"
            ></img>
          </div>
          <span
            class="material-symbols-outlined mr-1 hover:cursor-pointer text-slate-800 hover:text-slate-950 duration-200 ease-in-out"
            onClick={copyToClipboard}
          >
            {!copied ? "content_copy" : "done"}
          </span>
          <span
            onClick={() => callCallback("complete")}
            class="material-symbols-outlined hover:cursor-pointer text-slate-800 hover:text-slate-950 duration-200 ease-in-out animate-pulse"
          >
            arrow_upward_alt
          </span>
        </div>
      </div>
    </div>
  );
}

export function Typewriter({ text }) {
  const [displayedText, setDisplayedText] = useState("");
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index < text.length) {
      const timer = setTimeout(() => {
        setDisplayedText(displayedText + text.charAt(index));
        setIndex(index + 1);
      }, 8);
      return () => clearTimeout(timer);
    }
  }, [index, text, displayedText]);

  return (
    <div className="font-light bg-blue-50 text-slate-800 p-4 rounded-lg shadow-sm whitespace-pre-wrap text-sm">
      {displayedText}
    </div>
  );
}
