import React from "react";
import { Breadcrumb } from "../../Components/ContentPage/ContentRendering/Breadcrumb";
import FooterNav from "../../Components/ContentPage/Menus/FooterNav";
import { useNavigate } from "react-router-dom";

export default function Home({}) {
  const navigate = useNavigate("");

  function navigateTo(page) {
    navigate(page);
  }
  return (
    <div>
      <Breadcrumb page={"Home"} subPage={""} />
      <h1 className="font-semibold text-3xl mt-2">
        K-12 Prompt Engineering Guide
      </h1>
      <p className="mt-3 font-light text-md text-gray-800">
        {" "}
        Welcome to the K-12 Prompt Engineering Guide! This is a free,
        comprehensive guide for educators, administrators, and other K-12
        stakeholders who are interested in learning about Artificial
        Intelligence (AI) and its potential application in K-12 teaching and
        learning.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        We created this guide to address the overwhelming amount of information
        about AI in education that is circulating on the Internet and in the
        media. Our goal is to help you make better sense of AI and its potential
        in K-12. We want to put you in a position to make informed decisions
        about AI and its use in teaching and learning.
        {/* about AI and its use in teaching and learning. We have synthesized and
      organized the salient information in an easy-to-read format that
      includes a sequence of articles that begins with the fundamentals of AI
      and ends with practical step-by-step guides on how you can use AI in
      your work. */}
      </p>

      {/* <p className="mt-6 font-light text-md text-gray-800">
        A key component of the guide is how to effectively ‘prompt’ popular
        applications such as ChatGPT. Prompting is the act of invoking a
        language model (e.g., ChatGPT) to produce a desired response. In other
        words, we teach you how to make the most out of AI as an educator. This
        guide will help you be an effective user of AI applications like
        ChatGPT.
      </p> */}
      <p className="mt-6 font-semibold text-md text-gray-800">
        We recommend you start in 1 of 3 places:
      </p>
      <p className="mt-3 font-light text-md text-gray-800">
        If you are completely new to prompting, LLMs, or AI in the classroom,
        start with:{" "}
        <div
          className="hover:underline text-blue-700 hover:cursor-pointer inline-block duration-300 ease-in-out"
          onClick={() => navigateTo("/introduction")}
        >
          {""}
          Introduction
        </div>
      </p>
      <p className="mt-3 font-light text-md text-gray-800">
        If you are familiar with prompting and want to skip the preamble, start
        with:{" "}
        <div
          className="hover:underline text-blue-700 hover:cursor-pointer inline-block duration-300 ease-in-out"
          onClick={() => navigateTo("/strategies")}
        >
          {""}
          Strategies
        </div>
      </p>
      <p className="mt-3 font-light text-md text-gray-800">
        If you have experience prompting and want to learn new techniques, start
        with:{" "}
        <div
          className="hover:underline text-blue-700 hover:cursor-pointer inline-block duration-300 ease-in-out"
          onClick={() => navigateTo("/usecases")}
        >
          {" "}
          Use Cases
        </div>
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        The K-12 Prompt Guide is designed to be read in order. We encourage you,
        however, to use the guide in the way that makes the most sense to you.
        We also encourage you to share the guide with your colleagues.
      </p>
      {/* <p className="mt-6 font-light text-md text-gray-800">
        To use this guide, there is no prerequisite knowledge of or experience
        with AI needed. We have included references to the research throughout
        in case you would like to further explore topics we have shared.{" "}
      </p> */}
      <p className="mt-6 font-light text-md text-gray-800">
        The guide is a living resource that will be frequently updated and
        maintained. This resource was developed by{" "}
        <a
          className="hover:underline hover:cursor-pointer duration-300 ease-in-out text-blue-700"
          href="https://twitter.com/samuelcrombie"
          target="_blank"
          rel="noreferrer"
        >
          Sam Crombie
        </a>
        {" (Microsoft)"},{" "}
        <a
          className="hover:underline hover:cursor-pointer duration-300 ease-in-out text-blue-700"
          href="https://twitter.com/theedtechdoctor"
          target="_blank"
          rel="noreferrer"
        >
          Tim Green
        </a>
        {" (California State University Fullerton)"}, and{" "}
        <a
          className="hover:underline hover:cursor-pointer duration-300 ease-in-out text-blue-700"
          href="https://twitter.com/omarsar0"
          target="_blank"
          rel="noreferrer"
        >
          Elvis Saravia
        </a>
        {" (dair.ai)"}.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        Interested in collaborating with other K-12 educators applying AI to
        their classrooms? Join our{" "}
        <a
          href="https://www.facebook.com/groups/k12promptengineeringguide"
          target="_blank"
          className="hover:cursor-pointer hover:underline text-blue-700"
          rel="noreferrer"
        >
          {"Facebook group"}
        </a>
        {"!"}
      </p>
      <FooterNav pageBefore={""} pageAfter={"Introduction"} />
    </div>
  );
}
