import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function MobileContentMenu({ selectedPage, selectedCallback }) {
  return (
    <div className="">
      <div className="flex flex-col px-4 py-4 gap-1 h-screen">
        <MenuItem
          itemTitle={"Home"}
          menuItems={[]}
          currentPage={selectedPage}
          selectedCallback={selectedCallback}
        />
        <MenuItem
          itemTitle={"Introduction"}
          menuItems={[
            "Artificial Intelligence",
            "Language Models",
            "Prompt Engineering",
          ]}
          currentPage={selectedPage}
          selectedCallback={selectedCallback}
        />
        <MenuItem
          itemTitle={"Strategies"}
          menuItems={[
            "Precision",
            "Labeling",
            "Examples",
            // "Decomposition",
            "Contextualization",
          ]}
          currentPage={selectedPage}
          selectedCallback={selectedCallback}
        />
        <MenuItem
          itemTitle={"Use Cases"}
          menuItems={["MCQs", "FRQs", "Grading MCQs"]}
          currentPage={selectedPage}
          selectedCallback={selectedCallback}
        />
        <MenuItem
          itemTitle={"Common Questions"}
          menuItems={[]}
          currentPage={selectedPage}
          selectedCallback={selectedCallback}
        />
        <MenuItem
          itemTitle={"Tools"}
          menuItems={[]}
          currentPage={selectedPage}
          selectedCallback={selectedCallback}
        />
      </div>
    </div>
  );
}

export function MenuItem({
  itemTitle,
  menuItems,
  isSelected,
  selectedCallback,
}) {
  const navigate = useNavigate("");
  function navigateTo(page) {
    page = page.replace(" ", "").toLowerCase();
    let path = getPathFromName(page);
    selectedCallback();
    navigate(path);
  }

  const [isOpen, setIsOpen] = useState(false);
  function changeOpen(itemTitle) {
    setIsOpen(!isOpen);
    if (!isOpen === false) {
      navigateTo(itemTitle);
    }
  }

  let isSinglePage = false;
  if (menuItems.length === 0) {
    isSinglePage = true;
  }

  return (
    <div className="overflow-hidden">
      {/* Menu title */}
      <div
        onClick={() => changeOpen(itemTitle)}
        className={`flex flex-row justify-between items-center px-2 hover:bg-gray-100 rounded-md hover:cursor-pointer hover:text-black duration-200 ease-in-out
          ${
            isSelected === itemTitle
              ? "text-blue-700 bg-blue-50 font-normal"
              : "font-light text-gray-600"
          }
        `}
      >
        <h1 className={`text-sm`}>{itemTitle}</h1>
        <div>
          {isSinglePage ? (
            <div>
              <span
                className={`
                  material-symbols-outlined text-md mt-1 opacity-0
                  `}
              >
                chevron_right
              </span>
            </div>
          ) : (
            <div>
              <span
                className={`
                  material-symbols-outlined text-md mt-1 duration-700 ease-in-out
                  ${isOpen ? "rotate-90" : "rotate-0"}
                  `}
              >
                chevron_right
              </span>
            </div>
          )}
        </div>
      </div>

      {/* Submenu items */}
      {isSinglePage ? (
        <div></div>
      ) : (
        <div>
          {menuItems.map((item) => (
            <div
              className={`
            flex flex-col justify-start items-start border-l border-gray-400 border-solid ml-4 pl-2 duration-700 ease-in-out text-gray-600 hover:text-black gap-[2px]
            ${isOpen ? "max-h-96 opacity-100" : "max-h-0 opacity-0"}
            `}
              onClick={() => navigateTo(item)}
            >
              <h1
                className={`
                    text-start px-2 py-1 hover:bg-gray-100 rounded-md hover:cursor-pointer text-sm w-full duration-200 ease-in-out font-light hover:text-black
                    ${isSelected === item ? "text-blue-700 bg-blue-100" : ""}
                  `}
              >
                {item}
              </h1>
            </div>
          ))}
        </div>

        // <div>
        //   <div
        //     className={`
        //     mt-1 flex flex-col justify-start items-start border-l border-gray-400 border-solid ml-4 pl-2 duration-700 ease-in-out text-gray-600 hover:text-black gap-[2px]
        //     ${isOpen ? "max-h-96 opacity-100" : "max-h-0 opacity-0"}
        //     `}
        //     onClick={() => navigateTo()}
        //   >
        //     {menuItems.map((item) => (
        //       <h1
        //         className={`
        //             text-start px-2 py-1 hover:bg-gray-100 rounded-md hover:cursor-pointer text-sm w-full duration-200 ease-in-out font-light hover:text-black
        //             ${isSelected === item ? "text-blue-700 bg-blue-100" : ""}
        //           `}
        //       >
        //         {item}
        //       </h1>
        //     ))}
        //   </div>
        // </div>

        // <div
        //   className={`
        //     mt-1 flex flex-col justify-start items-start border-l border-gray-400 border-solid ml-4 pl-2 duration-700 ease-in-out text-gray-600 hover:text-black gap-[2px]
        //     ${isOpen ? "max-h-96 opacity-100" : "max-h-0 opacity-0"}
        //     `}
        //   onClick={() => navigateTo()}
        // >
        //   {menuItems.map((item) => (
        //     <h1
        //       className={`
        //             text-start px-2 py-1 hover:bg-gray-100 rounded-md hover:cursor-pointer text-sm w-full duration-200 ease-in-out font-light hover:text-black
        //             ${isSelected === item ? "text-blue-700 bg-blue-100" : ""}
        //           `}
        //     >
        //       {item}
        //     </h1>
        //   ))}
        // </div>
      )}
    </div>
  );
}

export function getPathFromName(name) {
  let path = "";

  if (name === "home") {
    path = `/home`;
  }
  if (name === "introduction") {
    path = `/introduction`;
  }
  if (
    name === "artificialintelligence" ||
    name === "languagemodels" ||
    name === "promptengineering"
  ) {
    path = `/introduction/${name}`;
  }
  if (name === "strategies") {
    path = `/strategies`;
  }
  if (
    name === "precision" ||
    name === "labeling" ||
    name === "examples" ||
    // name === "decomposition" ||
    name === "contextualization"
  ) {
    path = `/strategies/${name}`;
  }

  if (name === "usecases") {
    path = `/usecases`;
  }
  if (name === "mcqs" || name === "frqs" || name === "gradingmcqs") {
    path = `/usecases/${name}`;
  }

  if (name === "commonquestions") {
    path = `/commonquestions`;
  }

  if (name === "tools") {
    path = `/tools`;
  }

  return path;
}
