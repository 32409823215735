import React from "react";
import { Breadcrumb } from "../../Components/ContentPage/ContentRendering/Breadcrumb";
import FooterNav from "../../Components/ContentPage/Menus/FooterNav";

export default function Tools({}) {
  return (
    <div>
      <Breadcrumb page={"Tools"} subPage={""} />
      <h1 className="font-semibold text-3xl mt-2">Tools</h1>
      <p className="mt-3 font-light text-md text-gray-800">
        In this section, we catalog and review AI and non-AI education tools.
        With this in mind, we’ll be updating our list frequently. If you would
        like to review a tool for our list or if you have an idea for one we
        should try, please reach out{" "}
        <a
          href="https://www.facebook.com/groups/k12promptengineeringguide"
          target="_blank"
          className="hover:cursor-pointer hover:underline text-blue-700"
          rel="noreferrer"
        >
          {"here"}
        </a>
        {"."}
      </p>

      <div className="mt-6 bg-gray-50 p-4 rounded-md">
        <p className="font-bold text-2xl text-gray-800">
          <a
            href="https://curipod.com/ai"
            target="_blank"
            className="hover:cursor-pointer hover:underline text-blue-700"
            rel="noreferrer"
          >
            {"Curipod"}
          </a>
          {" | Instructional Activities"}
        </p>
        <p className="font-light text-md text-gray-800 mt-2">
          <span className="font-semibold">Description:</span> Curipod helps
          teachers construct interaction lessons for their students. It is an
          AI-powered digital slide and presentation platform that incorporates
          various interactive features, like word clouds, polls, drawings, and
          questions. Educators can use pre-made templates or design their own
          original lessons, and students can access the lessons using a special
          code or webpage. Curipod's AI-powered capabilities, which include
          question generation, lesson suggestions, and tailored feedback, are
          designed to help increase student participation and engagement.
        </p>

        <p className="font-light text-md text-gray-800 mt-2">
          <span className="font-bold">Cost:</span> Free/paid version
        </p>
      </div>

      <div className="mt-6 bg-gray-50 p-4 rounded-md">
        <p className="font-bold text-2xl text-gray-800">
          <a
            href="https://educationcopilot.com/"
            target="_blank"
            className="hover:cursor-pointer hover:underline text-blue-700"
            rel="noreferrer"
          >
            {"Education Copilot"}
          </a>
          {" | Lesson Planning"}
        </p>
        <p className="font-light text-md text-gray-800 mt-2">
          <span className="font-semibold">Description:</span> Copilot is a
          platform that helps educators create lesson plans, materials,
          handouts, and student reports.There are 10+ tools that are designed to
          help educators save time.
        </p>

        <p className="font-light text-md text-gray-800 mt-2">
          <span className="font-bold">Cost:</span> 30-day free trial / paid
          version
        </p>
      </div>

      <div className="mt-6 bg-gray-50 p-4 rounded-md">
        <p className="font-bold text-2xl text-gray-800">
          <a
            href="https://www.eduaide.ai/"
            target="_blank"
            className="hover:cursor-pointer hover:underline text-blue-700"
            rel="noreferrer"
          >
            {"Eduaide.ai"}
          </a>
          {" | Lesson Planning"}
        </p>
        <p className="font-light text-md text-gray-800 mt-2">
          <span className="font-semibold">Description:</span> Eduaide.ai is a
          platform designed to assist educators in creating lesson plans,
          teaching resources, and assessments. The platform offers a wide range
          of tools including AI-assisted lesson planning, teaching resource
          generation, assignment feedback, and assessment building. It is
          designed to streamline administrative tasks, and enhance instructional
          planning.
        </p>

        <p className="font-light text-md text-gray-800 mt-2">
          <span className="font-bold">Cost:</span> Free/paid version
        </p>
      </div>

      <div className="mt-6 bg-gray-50 p-4 rounded-md">
        <p className="font-bold text-2xl text-gray-800">
          <a
            href="https://www.hellohistory.ai/"
            target="_blank"
            className="hover:cursor-pointer hover:underline text-blue-700"
            rel="noreferrer"
          >
            {"Hello History"}
          </a>
          {" | Historical Chatbot"}
        </p>
        <p className="font-light text-md text-gray-800 mt-2">
          <span className="font-semibold">Description:</span> Hello History is
          an AI-supported tool that allows interactive conversations with over
          1000 historical figures, which can provide students with a unique way
          to learn about history. The platform uses AI to bring historical
          figures to life and make each conversation completely unique.
          Educators can use Hello History to generate lesson plans, class
          activities, and assignments, as well as to provide support to students
          through 24/7 available AI tutors. The tool aims to enhance student
          engagement, critical thinking, and understanding of history by turning
          historical events into interactive dialogues and role-play activities.
        </p>

        <p className="font-light text-md text-gray-800 mt-2">
          <span className="font-bold">Cost:</span> Free/paid version
        </p>
      </div>

      <div className="mt-6 bg-gray-50 p-4 rounded-md">
        <p className="font-bold text-2xl text-gray-800">
          <a
            href="https://www.magicschool.ai/"
            target="_blank"
            className="hover:cursor-pointer hover:underline text-blue-700"
            rel="noreferrer"
          >
            {"Magic School AI"}
          </a>
          {" | Instructional Activities"}
        </p>
        <p className="font-light text-md text-gray-800 mt-2">
          <span className="font-semibold">Description:</span> The Magic School
          AI platform includes tools to assist educators in various aspects of
          teaching. It provides 50+ AI-supported tools, like a rubric generator,
          IEP creator, and multiple-choice assessment generator. The goal of
          Magic School is to help save educators time by automating tasks such
          as lesson planning, grading, and creating educational materials.
        </p>

        <p className="font-light text-md text-gray-800 mt-2">
          <span className="font-bold">Cost:</span> Free/paid version
        </p>
      </div>

      <div className="mt-6 bg-gray-50 p-4 rounded-md">
        <p className="font-bold text-2xl text-gray-800">
          <a
            href="https://www.perplexity.ai/"
            target="_blank"
            className="hover:cursor-pointer hover:underline text-blue-700"
            rel="noreferrer"
          >
            {"Perplexity AI"}
          </a>
          {" | Search Engine"}
        </p>
        <p className="font-light text-md text-gray-800 mt-2">
          <span className="font-semibold">Description:</span> Perplexity AI is
          an advanced tool that uses large language models to provide precise
          and comprehensive answers to queries that you provide. In essence, it
          is a search engine. When responding to a query, the platform will
          provide sources it used in providing the answer it provides. This
          allows you to look up the sources.
        </p>

        <p className="font-light text-md text-gray-800 mt-2">
          <span className="font-bold">Cost:</span> Free/paid version
        </p>
      </div>

      <div className="mt-6 bg-gray-50 p-4 rounded-md">
        <p className="font-bold text-2xl text-gray-800">
          <a
            href="https://www.quizalize.com/"
            target="_blank"
            className="hover:cursor-pointer hover:underline text-blue-700"
            rel="noreferrer"
          >
            {"Quizalize"}
          </a>
          {" | Assessment"}
        </p>
        <p className="font-light text-md text-gray-800 mt-2">
          <span className="font-semibold">Description:</span> Quizalize is a
          quiz development and assessment platform that offers a gamified
          approach similar to tools such as Kahoot! and Quizizz. Quizalize uses
          AI to add hints and explanations to questions that students are
          answering during an assessment. The platform. It also is designed to
          track student progress on assessments, which teachers can then use to
          assign follow-up activities to students.
        </p>

        <p className="font-light text-md text-gray-800 mt-2">
          <span className="font-bold">Cost:</span> Free/paid version
        </p>
      </div>

      <div className="mt-6 bg-gray-50 p-4 rounded-md">
        <p className="font-bold text-2xl text-gray-800">
          <a
            href="https://teacherbot.io/"
            target="_blank"
            className="hover:cursor-pointer hover:underline text-blue-700"
            rel="noreferrer"
          >
            {"Teacherbot"}
          </a>
          {" | Instructional Activities"}
        </p>
        <p className="font-light text-md text-gray-800 mt-2">
          <span className="font-semibold">Description:</span> Create different
          instructional activities or choose from lists of premade instructional
          activities.
        </p>

        <p className="font-light text-md text-gray-800 mt-2">
          <span className="font-bold">Cost:</span> Free/paid version
        </p>
      </div>

      <FooterNav pageBefore={"Common Questions"} pageAfter={"Introduction"} />
    </div>
  );
}
