import MenuItem from "./MenuItem";

export default function ContentMenu({ selectedPage }) {
  return (
    <div className="w-64 top-0 left-0 mt-16 bg-white fixed z-40 max-[800px]:-translate-x-64 max-[1400px]:w-0 duration-500 ease-in-out">
      <div className="flex flex-col w-64 px-4 py-8 gap-1 border-r border-gray-100 border-solid h-screen">
        <MenuItem
          itemTitle={"Home"}
          menuItems={[]}
          currentPage={selectedPage}
        />
        <MenuItem
          itemTitle={"Introduction"}
          menuItems={[
            "Artificial Intelligence",
            "Language Models",
            "Prompt Engineering",
          ]}
          currentPage={selectedPage}
        />
        <MenuItem
          itemTitle={"Strategies"}
          menuItems={[
            "Precision",
            "Labeling",
            "Examples",
            // "Decomposition",
            "Contextualization",
          ]}
          currentPage={selectedPage}
        />
        <MenuItem
          itemTitle={"Use Cases"}
          menuItems={[
            "MCQs",
            "FRQs",
            "Grading MCQs",
            "Grading FRQs",
            "Instruction Feedback",
            "Role Playing",
          ]}
          currentPage={selectedPage}
        />
        <MenuItem
          itemTitle={"Common Questions"}
          menuItems={[]}
          currentPage={selectedPage}
        />
        <MenuItem
          itemTitle={"Tools"}
          menuItems={[]}
          currentPage={selectedPage}
        />
      </div>
    </div>
  );
}
