import React from "react";
import { Breadcrumb } from "../../Components/ContentPage/ContentRendering/Breadcrumb";
import FooterNav from "../../Components/ContentPage/Menus/FooterNav";

export default function LanguageModels({}) {
  return (
    <div>
      <Breadcrumb page={"Introduction"} subPage={"Language Models"} />
      <h1 className="font-semibold text-3xl mt-2">Language Models</h1>
      <p className="mt-3 font-light text-md text-gray-800">When you use ChatGPT or Google Bard, you are encountering a type of ML called language modeling. A language model attempts to reproduce human understanding of language and then do something useful with this understanding.</p>
      <p className="mt-6 font-light text-md text-gray-800">
        This language comprehension can be used to:
      </p>
      <ul className="list-disc list-inside pl-4 font-light text-sm text-gray-800">
        <li>Translate from Spanish to English</li>
        <li>Identify and flag harmful language in a social media post</li>
        <li>Process and respond to a customer support request</li>
        <li>Many others…</li>
      </ul>
      <p className="mt-6 font-light text-md text-gray-800">
        The type of language model used for ChatGPT and Google Bard is called a Large Language Model (LLM). An LLM is just a (very) large language model, where “large” is the scope of the model’s knowledge and computational abilities. It is the size and scale of this type of language model that helps explain their 
        <a
          href="https://en.wikipedia.org/wiki/Neural_scaling_law"
          target="_blank"
          className="hover:cursor-pointer hover:underline text-blue-700"
          rel="noreferrer"
        >
          {" "}impressive capabilities
        </a>
        
        . Note that LLM and ChatGPT/Bard are often used interchangeably - for the purpose of discussion, you can consider them the same thing.
      </p>
      <p className="mt-6 font-light text-md text-gray-800">
        An LLM can be thought of as a large and complex equation that takes a text input (a prompt) and produces a text output (a completion). When you enter a prompt into an LLM like ChatGPT, the model performs hundreds of trillions of calculations (hence ‘large’) which calculate the sequence of text you see as an output. How and why an LLM produces its outputs is an 
        <a
          href="https://timkellogg.me/blog/2023/10/01/interpretability"
          target="_blank"
          className="hover:cursor-pointer hover:underline text-blue-700"
          rel="noreferrer"
        >
         {" "}active area of research </a>
        called interpretability. 
      </p>
      <FooterNav
        pageBefore={"Artificial Intelligence"}
        pageAfter={"Prompt Engineering"}
      />
    </div>
  );
}
